<template>
    <div class="outDiv">
        <el-row class="row_one">
            <el-row class="row_two">
                <div class="top">
                    <el-col :span="4">
                        <div class="inner">
                            <label>状态</label>
                            <el-select v-model="dispatchSelect" clearable  @change="stateChange">
                                <el-option v-for="item in taskStatusArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="20" class="rightTitle">
                        <div class="titleDiv">
                            <div class="titleIcon"></div>
                            <label>巡检照片管理</label>
                        </div>
                    </el-col>
                </div>
            </el-row>
            <el-row class="row_three">
                <el-col :span="4">
                    <div class="bottom">
                        <div class="inner">
                            <div class="taskItem" v-for="(item) in taskArrs" :key="item.id" @click="clickTaskItem(item.id,item.taskid)" :class="{'taskActive':curId === item.id}">
                                <el-form label-position="right" :label-width="'45%'">
                                    <el-form-item label="任务内容">
                                        <el-tooltip :content="item.plantaskcontent" placement="right" effect="light" v-if="item.plantaskcontent && item.plantaskcontent.length > 6">
                                            <label>{{item.plantaskcontent.length > 6 ? item.plantaskcontent.slice(0,4) + '...' : item.plantaskcontent}}</label>
                                        </el-tooltip>
                                        <label v-else>{{item.plantaskcontent}}</label>
                                    </el-form-item>
                                    <el-form-item label="开始时间">
                                        <label>{{item.planbegintime}}</label>
                                    </el-form-item>
                                    <el-form-item label="结束时间">
                                        <label>{{item.planendtime}}</label>
                                    </el-form-item>
                                    <el-form-item label="任务状态">
                                        <label style="color:#1F9999">{{item.taskstatus}}</label>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="noData" v-if="firstNull">
                                <label>暂无数据</label>
                            </div>
                        </div>
                        <div class="pagination" v-if="!firstNull">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="[10,20,50,100]"
                                :page-size="pageSize"
                                :pager-count="5"
                                layout="prev, pager, next"
                                :total="totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
                <el-col :span="20" class="rightBox">
                    <el-row>
                        <el-col :span="5">
                            <div class="top">
                                <div class="lineTotalDiv">
                                    <el-form label-position="right" :label-width="'40%'">
                                        <el-form-item label="共有航线">
                                            <label class="totalCount">{{totalPage2}}条</label>
                                        </el-form-item>
                                        <el-form-item label="航线名称">
                                            <el-input v-model="airLineName" placeholder="请输入" clearable style="width:80%" @keydown.enter.native="queryAirLineInfo" @change="searchChange"></el-input>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="inner">
                                    <div class="taskItem" v-for="(item,index) in airLineArrs" :key="item.id" @click="clickTaskItem2(index,item.id)" :class="{'taskActive':curId2 === item.id}">
                                        <el-form label-position="right" :label-width="'50%'">
                                            <el-form-item label="航线名称">
                                                <el-tooltip :content="item.name" placement="right" effect="light" v-if="item.name && item.name.length > 5">
                                                    <label>{{item.name.length > 5 ? item.name.slice(0,5) + '...' : item.name}}</label>
                                                </el-tooltip>
                                                <label v-else>{{item.name}}</label>
                                            </el-form-item>
                                            <el-form-item label="是否已派工">
                                                <label>{{item.isDispatch}}</label>
                                            </el-form-item>
                                            <el-form-item label="当前派工">
                                                <el-tooltip :content="item.person" placement="right" effect="light" v-if="item.person && item.person.length > 7">
                                                    <label>{{item.person.length > 7 ? item.person.slice(0,7) + '...' : item.person}}</label>
                                                </el-tooltip>
                                                <label v-else>{{item.person?item.person:'--'}}</label>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                    <div class="noData" v-if="secondNull">
                                        <label>暂无数据</label>
                                    </div>
                                </div>
                                <!-- <div class="pagination">
                                    <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage2"
                                        :page-sizes="[10,20,50,100]"
                                        :page-size="pageSize"
                                        :pager-count="3"
                                        layout="prev, pager, next"
                                        :total="totalPage2">
                                    </el-pagination>
                                </div> -->
                            </div>
                        </el-col>
                        <el-col :span="19">
                            <slot></slot>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>
<script>
import api_uav from '@/utils/api/api_uav'
// import ElPagination2 from '@/utils/pagination/src/pager.vue'
export default {
    name:'OutDiv',
    components:{
        // ElPagination2
    },
    data(){
        return{
            firstNull:false,
            secondNull:false,
            dispatchSelect:'',
            airLineName:'',
            currentPage:1,
            currentPage2:1,
            pageSize:5,
            curId:0,
            curId2:0,
            curTaskId:0,
            recordFlag:2,
            totalPage:0,
            totalPage2:0,
            taskStatusArr:[
                {
                    label:'未派单',
                    value:1,
                },
                {
                    label:'已派单',
                    value:2,
                },
                {
                    label:'已接单',
                    value:3,
                },
                {
                    label:'已回执',
                    value:4,
                },
                {
                    label:'归档',
                    value:5,
                }
            ],
            taskArrs:[],
            airLineArrs:[],
        }
    },
    mounted(){
        this.queryTaskInfo()
    },
    methods:{
        queryAirLineInfo(){
            let params = {
                airLineName: this.airLineName,
                patrolTaskId: this.curTaskId
            }
            this.secondNull = true
            api_uav.queryAirLineInfo(params).then(res => {
                if(res.data.code === 200){
                    if(res.data.rows.length < 1){
                        
                    }else{
                        this.secondNull = false
                    }
                    this.airLineArrs = res.data.rows
                    if(this.airLineArrs.length > 0){
                        this.curId2 = this.airLineArrs[0].id
                        let data = {
                            taskid: this.curTaskId,
                            id:this.curId2
                        }
                        this.$emit('sendTaskId',data)
                    }else{
                        this.$emit('sendTaskId',null)
                    }
                    this.totalPage2 = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        searchChange(){
            if(!this.airLineName){
                this.queryAirLineInfo()
            }
        },
        stateChange(){
            this.currentPage = 1
            this.queryTaskInfo()
        },
        queryTaskInfo(){
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                taskstatus: this.dispatchSelect,
            }
            this.firstNull = true
            api_uav.queryPatrolTask(params).then(res => {
                if(res.data.code === 200){
                    if(res.data.rows.length < 1){
                    }else{
                        this.firstNull = false
                    }
                    this.taskArrs = res.data.rows
                    this.taskArrs = this.taskArrs.map(x => {
                        x.taskstatus = this.taskStatusArr.filter(y => y.value === Number(x.taskstatus)).length > 0 ? this.taskStatusArr.filter(y => y.value === Number(x.taskstatus))[0].label : x.taskstatus
                        return x
                    })
                    this.recordFlag--
                    if(this.recordFlag > 0){
                        this.curId = this.taskArrs[0].id
                        this.curTaskId = this.taskArrs[0].taskid
                        this.queryAirLineInfo()
                    }
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange2(val){
            this.currentPage2 = 1
            this.pageSize = val
            this.queryTaskInfo()
        },
        handleCurrentChange2(val){
            this.currentPage2 = val
            this.queryAirLineInfo()
        },
        handleSizeChange(val){
            this.currentPage = 1
            this.pageSize = val
            this.queryTaskInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryTaskInfo()
        },
        clickTaskItem2(index,id){
            this.curId2 = id
            let data = {
                taskid: this.curTaskId,
                id:this.curId2
            }
            this.$emit('sendTaskId',data)
        },
        clickTaskItem(id,taskid){
            this.curId = id
            this.curTaskId = taskid
            this.queryAirLineInfo()
        },
    },
}
</script>
<style lang="stylus" scoped>
@media screen and (max-width: 1700px)
    >>>.el-col-19
        width: calc(100% - 250px) !important
    >>>.el-col-20
        width: calc(100% - 235px) !important
>>>.el-form-item__content
>>>.el-form-item__label
    line-height: 5vh !important
>>>.el-form-item__label
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #495C6A;
.taskItem:last-child {
    margin-bottom: 15px
}
.noData
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size:15px
    color:grey
.outDiv
    width: 100%
    height: 100%
    overflow-y: hidden
    >>>.el-col-5
    >>>.el-col-4
        min-width: 230px !important
        border-right: 1px solid rgba(73,92,106,0.2);
    .row_one
        height: 100%
        .row_two
            height: 8%
            .top
                height: 100%
                width: 100%
                display: flex
                // justify-content: center
                align-items: center
                font-size: 14px
                border-bottom: 1px solid rgba(73,92,106,0.2);
                >>>.el-select
                    width: 80%
                    margin-left: 10px
                >>>.el-select
                >>>.el-input
                >>>.el-input__inner
                    height: 36px
                .rightTitle
                    display: flex
                    align-items: center
                    padding-left: 20px
                    .titleDiv
                        height: 20px
                        .titleIcon
                            display: inline-block
                            width: 4px;
                            height: 20px;
                            background: #1F9999;
                            border-radius: 0px 0px 0px 0px;
                            opacity: 1;
                        label
                            font-weight: bold
                            font-size: 16px
                            position: relative
                            bottom: 3px
                            left:4px
                .inner
                    
                    // border-right: 1px solid rgba(73,92,106,0.2);
                    padding-left: 10%
                    min-width 224px
                    height: 100%
                    display: inline-flex
                    justify-content: center
                    align-items: center
                    >>>.el-input
                        width:80%
        .row_three
            height: 92%
            width: 100%
            .rightBox
                .taskActive
                    border: 1px solid #199999 !important
                .taskItem
                    height: 15% !important
                    >>>.el-form-item
                        height: 23.5%  !important
                >>>.el-row
                    height: 100%
                .top
                    height: 15%
                    width: 100%
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 14px
                    border-bottom: 1px solid rgba(73,92,106,0.2);
                    .lineTotalDiv
                        width: 100%
                        >>>.el-form
                            .el-form-item
                                margin-bottom: 0px
                            .totalCount
                                color #199999
                                font-size: 14px;
                                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                                font-weight: bold;
                    >>>.el-select
                        width: 80%
                        margin-left: 10px
                    >>>.el-select
                    >>>.el-input
                    >>>.el-input__inner
                        height: 36px
                    .inner
                        width: 100%
                        display: flex
                        justify-content: center
                        align-items: center
                .bottom
                    height: 85%
                    overflow-y: auto
                .bottom::-webkit-scrollbar {
                    width : 5px;
                    height: 1px;
                }
                .bottom::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
                    background   : #ededed;
                }
                .bottom::-webkit-scrollbar-track {
                    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
                    border-radius: 10px;
                    background   : transparent;
                }
                .pagination
                    display: block
                    position: absolute
                    bottom: 5%
                    right: 0px
            .bottom
                position: relative
                height: 100%
                display: flex
                justify-content: center
                .pagination
                    display: block
                    position: absolute
                    bottom: 5%
                    right: 0px
                    >>>.el-pager li{
                        border: 1px solid #D9D9D9;
                        border-radius: 4px;
                        min-width:23px !important;
                        height: 23px !important;
                        margin: 0 2px 0 2px !important;
                        line-height:23px !important
                    }
                    >>>.el-pagination button, .el-pagination span:not([class*=suffix]){
                        min-width:23px !important;
                        height: 23px !important;
                        line-height:20px !important;
                    }
                    >>>.el-pager li:hover{
                        color: #199999 !important;
                    }
                    >>>.el-pager li.active{
                        background: #199999;
                        color: white !important;
                    }
                    >>>.el-pagination .btn-next, .el-pagination .btn-prev{
                        border: 1px solid #D9D9D9;
                        border-radius: 4px;
                        width: 20px !important;
                        margin: 0 4px !important;
                    }
                    >>>.el-pagination .btn-next, .el-pagination .btn-prev :hover{
                        color: #199999 !important;
                    }
                .inner
                    height: 100%
                    width: 90%
                .taskActive
                    border: 1px solid #199999 !important
                    // background: rgba(250,250,250,0.5) !important
                .taskItem
                    &:hover
                        border: 1px solid #199999
                    height: 15%
                    width: 100%
                    border: 1px solid rgba(73,92,106,0.2);
                    display: inline-flex
                    justify-content: center
                    margin-top: 5%
                    >>>.el-form
                        height: 100%
                        width: 100%
                    >>>.el-form-item
                        margin: 0 !important
                        height: 20%;
                        display: flex;
                    >>>.el-form-item__content
                        margin-left: 10% !important

</style>