<template>
    <div class="pictureManager">
        <!-- <data-out-div ref="dataOutDiv" @sendLineId="sendLineId"> -->
            <div class="rightContent">
                <div style="height:100%">
                    <div class="top_first">
                        <div>
                            <label>设备名称</label>
                            <el-input v-model="deviceName" placeholder="请输入名称" clearable @change="searchChange" @keydown.enter.native="goSearch"></el-input>
                        </div>
                        <div class="deviceType">
                            <label>设备类型</label>
                            <el-select v-model="deviceType">
                                <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="Btn">
                            <el-button class="search" @click="goSearch">查询</el-button>
                            <el-button class="reset" @click="resetSearch">重置</el-button>
                        </div>
                    </div>
                    <!-- <div class="middle_title">
                        <div class="inner">
                            <el-button @click="handleNewAdd" class="newAdd">新增</el-button>
                            <el-button @click="resetSort" class="reSort">重新排序</el-button>
                        </div>
                    </div> -->
                    <!-- <div class="bottom_title">
                        <div class="inner">
                            <div class="left_icon"></div>
                            <div class="text">基本信息</div>
                            <el-button @click="handleNewAdd" style="right:115px">新增</el-button>
                            <el-button @click="resetSort" class="reSort">重新排序</el-button>
                        </div>
                    </div> -->
                    <div class="bottom">
                        <div class="inner">
                            <el-table
                                class="airlineDeviceTable"
                                resizable
                                highlight-current-row
                                row-key="id"
                                :data="tableData"
                                style="width:100%"
                                :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                                tooltip-effect="light"
                                max-height="calc(100vh - 280px)"
                                :row-style="{height: '50px'}"
                                :cell-style="{padding: '0.505%'}"
                                >
                                <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                    align="center"
                                    show-overflow-tooltip
                                    :label="item.label"
                                    :prop="item.prop"
                                    width="auto"
                                    sortable>
                                </el-table-column>
                                <!-- <el-table-column
                                    align="center"
                                    label="操作"
                                    width="150">
                                    <template slot-scope="scope">
                                        <el-button @click="editItemPicCount(scope.row.id,scope.row.pictureNum)" type="text" size="small" class="greenBtn">修改照片数</el-button>
                                        <el-button  @click="handleClick(scope.row)" type="text" size="small" class="redBtn">删除</el-button>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10,20,50,100]"
                            :page-size="10"
                            layout="total, prev, pager, next, sizes, jumper"
                            :total="totalPage">
                        </el-pagination>
                    </div>
                </div>
            </div>
        <!-- </data-out-div> -->
        <el-dialog
            title="新增航线设备"
            center
            v-drag
            :visible.sync="newAddShow"
            width="80%">
            <div style="width:100%">
                <el-row style="width:100%" class="top-row">
                    <el-col :span="6">
                        <div style="width: auto">
                            <label style="margin-right: 10px">变电站名称</label>
                            <el-input v-model="deviceName" placeholder="请输入" style="width:120px"></el-input>
                            <el-button>查询</el-button>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div style="width: auto">
                            <label style="margin-right: 10px">关键词搜索</label>
                            <el-input v-model="filterText" placeholder="请输入" style="width:140px"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="width: auto" class="dialog_typeDiv">
                            <label style="margin-right: 10px">设备名称</label>
                            <el-input v-model="deviceName" placeholder="请输入" style="width:140px"></el-input>
                            <div class="dialog_type">
                                <label style="margin:0 10px">设备类型</label>
                                <el-select v-model="deviceType">
                                    <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"  style="width:140px"></el-option>
                                </el-select>
                            </div>
                            <el-button class="dialog_typeBtn">查询</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="width:100%" class="bottom-row">
                    <el-col :span="6">
                        <div style="width:100%">
                            <el-table
                                resizable
                                :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#259C9C',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                                tooltip-effect="light"
                                highlight-current-row
                                :data="newtableData"
                                max-height="450px"
                                height="450px"
                                stripe
                                border
                                :row-style="{height: '40px'}"
                                :cell-style="{padding: '0.505%'}"
                                style="width: 100%">
                                <el-table-column
                                prop="sort"
                                label="序号"
                                align="center"
                                show-overflow-tooltip
                                width="70">
                                </el-table-column>
                                <el-table-column
                                prop="equipName"
                                align="center"
                                show-overflow-tooltip
                                label="变电站名称"
                                width="auto">
                                </el-table-column>
                            </el-table>
                            <div class="pagination">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[10,20,50,100]"
                                    :page-size="10"
                                    layout="sizes, total, prev, next"
                                    :total="totalPage">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div style="width:100%">
                            <el-tree
                                class="filter-tree"
                                :data="treeData"
                                :props="defaultProps"
                                default-expand-all
                                :filter-node-method="filterNode"
                                ref="tree">
                            </el-tree>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="width:100%">
                            <el-table
                                resizable
                                :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#259C9C',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                                highlight-current-row
                                :data="newtableData2"
                                stripe
                                border
                                row-key="id"
                                max-height="450px"
                                height="450px"
                                tooltip-effect="light"
                                :row-style="{height: '40px'}"
                                :cell-style="{padding: '0.505%'}"
                                @selection-change="handleSelectionChange"
                                style="width: 100%">
                                <el-table-column
                                type="selection"
                                :reserve-selection="true" 
                                width="55">
                                </el-table-column>
                                <el-table-column
                                prop="sort"
                                label="序号"
                                align="center"
                                show-overflow-tooltip
                                width="55">
                                </el-table-column>
                                <el-table-column
                                prop="equipName"
                                align="center"
                                label="设备名称"
                                show-overflow-tooltip
                                width="auto">
                                </el-table-column>
                                <el-table-column
                                prop="equipType"
                                align="center"
                                label="设备类型"
                                show-overflow-tooltip
                                width="180">
                                </el-table-column>
                            </el-table>
                            <div class="pagination">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[10,20,50,100]"
                                    :page-size="10"
                                    layout="sizes, total, prev, next"
                                    :total="totalPage">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <!-- <el-form ref="form" :model="newAddForm" label-width="80px">
                    <el-form-item label="设备名称">
                        <el-input v-model="newAddForm.equipName" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="设备类型">
                        <el-select v-model="newAddForm.equipType">
                            <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goDispatch">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import DataOutDiv from '@/components/dataLedger/DataOutDiv'
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
import Sortable from 'sortablejs';
export default {
    name:'PictureManager',
    components:{
        DataOutDiv
    },
    data(){
        return{
            newtableData2:[],
            newtableData:[],
            editPicCount:false,
            deviceName:'',
            totalPage: 0,
            deviceType:'',
            deviceTypeArr:[
                {
                    label:'定点',
                    value:0,
                },
                {
                    label:'箱式变压器',
                    value:1,
                },
                {
                    label:'柱上变压器',
                    value:2,
                },
                {
                    label:'开闭所',
                    value:3,
                },
                {
                    label:'环网柜',
                    value:4,
                },
                {
                    label:'分支箱',
                    value:5,
                },
                {
                    label:'配电室',
                    value:6,
                },
                {
                    label:'杆塔',
                    value:7,
                },
                {
                    label:'开关',
                    value:8,
                },
                {
                    label:'其他',
                    value:9,
                },
            ],
            currentPage: 1,
            pageSize:10,
            newAddShow:false,
            newAddForm:{
                equipName:'',
                equipType:'',
            },
            tableColumns:[
                {
                    label:'序号',
                    prop:'sort',
                },
                {
                    label:'设备名称',
                    prop:'equipName',
                },
                {
                    label:'设备类型',
                    prop:'equipType',
                },
                {
                    label:'照片数',
                    prop:'pictureNum',
                },
            ],
            tableData: [],
            recordIds:[],
            picId:0,
            filterText:'',
            treeData: [{
            id: 1,
            label: '一级 1',
            children: [{
                id: 4,
                label: '二级 1-1',
                children: [{
                id: 9,
                label: '三级 1-1-1'
                }, {
                id: 10,
                label: '三级 1-1-2'
                }]
            }]
            }, {
            id: 2,
            label: '一级 2',
            children: [{
                id: 5,
                label: '二级 2-1'
            }, {
                id: 6,
                label: '二级 2-2'
            }]
            }, {
            id: 3,
            label: '一级 3',
            children: [{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            },{
                id: 7,
                label: '二级 3-1'
            }, {
                id: 8,
                label: '二级 3-2'
            }]
            }],
            defaultProps: {
            children: 'children',
            label: 'label'
            }
        // }

        }
    },
    props:{
        firstId:{
            type:String,
            default:null
        }
    },
    computed:{
        curLineId(){
            return this.$store.state.id
        }
    },
    mounted(){
        this.queryInfo()
        // this.$nextTick(() => {
        //     this.rowDrop();
        // });
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods:{
        handleSelectionChange(val){

        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        picNumBlur(){
            this.editPicCount = false
        },
        picNumChange(pictureNum){
            let params = {
                id: this.picId,
                pictureNum: pictureNum
            }
            this.axios.put('/api/airline/equip/updatePictureNum',params, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
                if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    this.queryInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        editItemPicCount(id,num){
            this.picId = id
            this.editPicCount = true
        },
        goSearch(){
            this.currentPage = 1
            this.queryInfo()
        },
        resetSort(){
            let oldIds = this.recordIds.map(x => x.id)
            let newIds = this.tableData.map(x => x.id)
            let sortArrs = []
            oldIds.forEach((item,index) => {
                if(newIds.indexOf(item) > -1){
                    sortArrs.push({
                        id:item,
                        sort:newIds.indexOf(item) + 1
                    })
                }
            })
            this.axios.put('/api/airline/equip/updateSort',sortArrs, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
              if(res.data.code === 200){
                    this.queryInfo()
                    this.$message.success(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        rowDrop(){
            const tbody = document.querySelector('.airlineDeviceTable .el-table__body-wrapper tbody');
			const that = this;
			Sortable.create(tbody, {
                draggable:".el-table__row",
				// 结束拖拽后的回调函数
				onEnd({ newIndex, oldIndex }) {
					console.log('拖动了行，当前序号：' + newIndex);
					const currentRow = that.tableData.splice(oldIndex, 1)[0];
					that.tableData.splice(newIndex, 0, currentRow);
				}
			})
        },
        searchChange(){
            if(!this.deviceName){
                this.currentPage = 1
                this.queryInfo()
            }
        },
        sendLineId(id){
            this.curLineId = id
            this.queryInfo()
        },
        handleClick(rows){
            api_uav.deleteLineDeviceInfo(rows.id).then(res => {
                if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    this.currentPage = 1
                    this.queryInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        resetSearch(){
            this.currentPage = 1
            this.deviceName = ''
            this.deviceType = ''
            this.queryInfo()
        },
        goDispatch(){
            let params = {
                equipName: this.newAddForm.equipName,
                equipType: this.newAddForm.equipType,
                airLineId: this.curLineId,
            }
            api_uav.addLineDeviceInfo(params).then(res => {
                if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    this.newAddShow = false
                    this.newAddForm = {
                        equipName:'',
                        equipType:'',
                    }
                    this.queryInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleNewAdd(){
            this.newAddShow = true
        },
        queryInfo(){
            let params = {
                ids:this.curLineId,
                equipName:this.deviceName,
                equipType:this.deviceType,
                currentPage:this.currentPage,
                pageSize:this.pageSize,
            }
            api_uav.queryLineDeviceInfo(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData = this.tableData.map(x => {
                        x.equipType = this.deviceTypeArr[x.equipType].label
                        x.pictureNum = x.pictureNum? x.pictureNum : '0'
                        return x
                    })
                    
                    this.newtableData2 = this.tableData
                    this.newtableData = this.tableData
                    this.recordIds = JSON.parse(JSON.stringify(this.tableData))
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.queryInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryInfo()
        }
    }
}
</script>
<style lang="stylus" scoped>
// @media screen and (max-width: 1700px)
//     .dialog_typeDiv
//         width: 70% !important
//     .dialog_type
//         display: block !important
//         label
//             margin-left:0 !important
//     .dialog_typeBtn
//         position absolute
//         top 20px
//         right 50px
//     >>>.el-input
//     >>>.el-input__inner
//         height: 25px !important
//     >>>.el-input__suffix
//         height: 27px !important
//     >>>.el-button
//         height: 25px !important
.pictureManager
    width: 100%
    height: 100%
    .editNum
        >>>.el-input__inner
            border 1px solid rgba(255,255,255,0.5) !important
            background: white !important
    .pictureCountInput
        >>>.el-input__inner
            text-align: center
            border 0px solid rgba(255,255,255,0.5) !important
            background: transparent
    >>>.el-dialog
        margin-top: 12vh !important
        min-width: 1128px 
        .dialog_type
            display:inline-block
        .el-row
            border 1px solid rgba(73,92,106,0.2); 
            border-right: none
        .top-row
            border-bottom none
            height: 70px
            .el-input
                width: 140px
            .el-button
                margin-left: 10px
                width: 60px
                height: 32px
                display: inline-flex
                align-items: center
                justify-content: center
                // background: #259C9C;
                border 1px solid #259C9C
                color: #259C9C
        .el-col-7
        .el-col-6
        .el-col-12
            // position relative
            border-right: 1px solid rgba(73,92,106,0.2); 
            display: flex
            justify-content: center
            align-items: center
        .bottom-row
            .el-col-7
            .el-col-6
            .el-col-12
                align-items: flex-start
                height: 500px
            .filter-tree
                height: 500px
                overflow-y: hidden
            .pagination
                // position absolute
                // right 10px
                float right
                margin-top : 5px
                margin-right -8px
        .el-dialog__header
            text-align: left
        .el-dialog__body
            // margin 30px 0 0 0
            padding: 20px !important
            display: flex
            justify-content: center
            align-items: center
        .el-input
        .el-input__inner
        .el-input__suffix
            height: 32px
        .el-input__icon
            line-height: 32px
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
    .rightContent
        width: 100%
        height: 100%
        position: relative
        .top_first
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 7%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
            .deviceType
                >>>.el-input
                    width: 100%
                    height: 36px
        .top_first
            padding:  2% 0 1% 0
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
        .middle_title
            height: 20px
            position: relative
            left -6px
            .inner
                div
                    display: inline-block
                .left_icon
                    width: 4px;
                    height: 20px;
                    background: #1F9999;
                    opacity: 1;
                .text
                    position: absolute
                    padding-left: 10px
                    padding-top: -5px
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #1F9999;
                    line-height: 20px;
                >>>.el-button
                    position: absolute
                    left: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
                .newAdd
                    position: absolute
                    left: 25px
                .reSort
                    position: absolute
                    left:105px
                    bottom:0px
                    background: white;
                    color: #259C9C
                    border: 1px solid #259C9C;
                .editCount
                    position: absolute
                    left: 195px
                    bottom:0px
                    background: #6BB8B9
                    color: white
        .bottom_title
            height: 20px
            position: relative
            padding-left: 40px
            .inner
                div
                    display: inline-block
                .left_icon
                    width: 4px;
                    height: 20px;
                    background: #1F9999;
                    opacity: 1;
                .text
                    position: absolute
                    padding-left: 10px
                    padding-top: -5px
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #1F9999;
                    line-height: 20px;
                >>>.el-button
                    position: absolute
                    right: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
                .reSort
                    background: white;
                    color: #259C9C
                    border: 1px solid #259C9C;
        .bottom
            width: 100%
            margin-top: 1%
            // height: calc(91% - 36px)
            height: 74.9%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 350px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 350px);
        .pagination
            position: absolute
            width: 100%
            height: 60px
            bottom: 0
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>