<template>
    <div class="home">
        <router-view name="login" v-if="(!token && !isLogin) || $route.path === '/login'"></router-view>
        <div v-if="(isLogin || token) && $route.path !== '/login'" style="width: 100%; height: 100%">
            <el-row class="title">
                <div class="title_first">
                    <img src="@images/common/titleIcon.png">
                    <label class="text">无人机自动巡检系统</label>
                </div>
                <!-- <div class="title_second">
                    <img src="@images/common/icon_search.png">
                    <el-input v-model="searchKey" placeholder="请输入关键字" clearable></el-input>
                </div> -->
                <div class="title_third">
                    <div class="user-icon">
                        <i class="el-icon-user"></i>
                    </div>
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            {{username?username:username2}}<i class="el-icon-arrow-down el-icon-arrow-right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="logout">注销</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <img class="userImg" src="@images/common/userImage.jpg"> -->
                </div>
            </el-row>
            <el-row class="content">
                <el-col :span="4">
                    <left-nav></left-nav>
                </el-col>
                <el-col :span="20" class="rightContent">
                    <div class="rightContent_inner">
                        <router-view></router-view>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import LeftNav from '@views/Nav'
export default {
    data(){
        return{
            activeIndex: '/',
            openIndexs:['1','2','3','4','5'],
            searchKey:'',
            isShowLogin: true,
            username2: ''
        }
    },
    watch: {
    },
    components:{
        LeftNav
    },
    computed: {
        isLogin() {
            return this.$store.state.isLogin
        },
        token() {
            return sessionStorage.getItem('token')
        },
        username() {
            return this.$store.state.username
        }
    },
    mounted(){
        if (!this.username) {
            this.username2 = sessionStorage.getItem('username')
        }
    },
    methods: {
        logout() {
            sessionStorage.setItem('token', '')
            sessionStorage.setItem("username", '')
            this.$store.commit('userLogin', {
                isLogin: false,
                username: ''
            })
            this.$router.push({path: '/login'});
        },
        handleSelect(key, keyPath) {
          // console.log(key, keyPath);
        },
    }
}
</script>
// 全局CSS
<style>
.el-table__empty-block{
    height: 50vh !important;
}
.el-table__body tr.current-row>td.el-table__cell{
    background-color: rgba(31,153,153,0.1) !important;
}
.el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: transparent !important; 
}
 .el-select-dropdown__item.selected{
     color: #199999 !important;
 }
 .el-select .el-input.is-focus .el-input__inner{
     border-color: #199999 !important;
 }
 .el-pagination__sizes .el-input .el-input__inner:hover{
     border-color: #199999 !important;
 }
 .el-select .el-input__inner:focus{
     border-color: #199999 !important;
 }
 .el-pager li{
     border: 1px solid #D9D9D9;
     border-radius: 4px;
     min-width:28px !important;
     height: 28px !important;
     margin: 0 2px 0 2px !important;
 }
 .el-pagination button, .el-pagination span:not([class*=suffix]){
     min-width:28px !important;
     height: 28px !important;
     line-height:28px !important;
 }
 .el-pager li:hover{
     color: #199999 !important;
 }
 .el-pager li.active{
     background: #199999;
     color: white !important;
 }
 .el-pagination .btn-next, .el-pagination .btn-prev{
     border: 1px solid #D9D9D9;
     border-radius: 4px;
     width: 20px !important;
     margin: 0 4px !important;
 }
 .el-pagination .btn-next, .el-pagination .btn-prev :hover{
     color: #199999 !important;
 }
 .el-table__fixed-right::before, .el-table__fixed::before{
     background-color: transparent !important;
 }
 .el-pagination .btn-next{
     padding-left: 6px !important;
 }
 .el-pager li.active+li{
     border-left: 1px solid #D9D9D9 !important;
 }
 .el-input__inner:focus{
     border-color: #199999 !important;
 }
.el-date-editor .el-input__inner {
  padding: 0 15px;
}
.el-date-editor .el-input__prefix{
  right: 5px;
  left: unset;
}
.el-date-editor  .el-input__inner{
    padding-left: 15px !important;
}
.el-date-editor .el-input__suffix{
  right: 25px;
}
.el-date-editor .el-input__icon{
  position: relative;
  bottom: 2px;
}
.el-button--primary{
    background-color: #259C9C !important;
    border-color: #259C9C !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color:#199999 !important;
    border-color:#199999 !important
}
</style>
<style lang="stylus" scoped>
.user-icon
    width: 20px
    height: 20px
    border-radius: 10px
    border: 1px solid black
    margin-right: 10px
    display: flex
    justify-content: center
    align-items: center
.home
    height: 100%
    .title
        position: relative
        padding-left: 31px
        height: 10%
        width: 100%
        display: inline-flex
        align-items: center
        .title_first
            img
                height: 45px
                width: 45px
            .text
                margin: 0 0 0 10px
                font-size: 27px;
                font-family: PangMenZhengDao-Regular, PangMenZhengDao;
                font-weight: 400;
                color: #1F9999;
                position: relative
                bottom:10px
        .title_second
            margin: 0 0 0 30px
            width: 300px
            display: flex
            align-items: center
            img
                margin: 0 10px 0 0
            >>>.el-input
                width: 200px
            >>>.el-input__inner
                background: transparent
                border: none
                &:focus
                    background: #fff
        .title_third
            position: absolute
            right: 10px
            display: flex
            align-items: center
            img
                margin-right: 10px
            .userImg
                width: 28px
                height: 28px
                border-radius: 50px
                margin-left: 10px
    .content
        height: 90%
    .rightContent
        .rightContent_inner
            border-radius: 8px 0px 0px 8px;
            position: relative
            background: white
            width: 99%
            height: 98%
    >>>.el-col
        height: 100%
</style>