<template>
  <div class="pictureManager">
    <data-out-div ref="dataOutDiv" @sendLineId="sendLineId">
      <div class="rightContent">
        <div style="height: 100%">
          <div class="top_first">
            <div>
              <label>设备名称</label>
              <el-input
                v-model="deviceName"
                placeholder="请输入名称"
                clearable
                @change="searchChange"
                @keydown.enter.native="goSearch"
              ></el-input>
            </div>
            <div class="deviceType">
              <label>设备类型</label>
              <el-select v-model="deviceType">
                <el-option
                  v-for="item in deviceTypeArr"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="Btn">
              <el-button class="search" @click="goSearch">查询</el-button>
              <el-button class="reset" @click="resetSearch">重置</el-button>
            </div>
          </div>
          <div class="middle_title">
            <div class="inner">
              <el-button @click="handleNewAdd" class="newAdd">新增</el-button>
              <el-button
                @click="handleNetNewAdd"
                style="margin-left: 90px"
                class="netNewAdd"
                >外网新增</el-button
              >
              <el-button
                style="
                  margin-left: 180px;
                  border: 1px solid red;
                  color: red;
                  background: white;
                "
                @click="handleDeleteMost"
                >批量删除</el-button
              >
              <el-button
                @click="resetSort"
                style="margin-left: 190px"
                class="reSort"
                >重新排序</el-button
              >
              <!-- <el-button @click="editPicCount" class="editCount">修改照片数</el-button> -->
            </div>
          </div>
          <!-- <div class="bottom_title">
                        <div class="inner">
                            <div class="left_icon"></div>
                            <div class="text">基本信息</div>
                            <el-button @click="handleNewAdd" style="right:115px">新增</el-button>
                            <el-button @click="resetSort" class="reSort">重新排序</el-button>
                        </div>
                    </div> -->
          <div class="bottom">
            <div class="inner">
              <el-table
                ref="multipleTable"
                resizable
                highlight-current-row
                row-key="id"
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{
                  background: '#FAFAFA',
                  fontWeight: 400,
                  color: '#000000',
                  fontSize: '14px',
                  fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN',
                }"
                :default-sort="{ prop: 'date', order: 'descending' }"
                tooltip-effect="light"
                max-height="calc(100vh - 250px)"
                :row-style="{ height: '40px' }"
                :cell-style="{ padding: '0.505%' }"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  show-overflow-tooltip
                  type="selection"
                  :reserve-selection="true"
                  width="55"
                >
                </el-table-column>
                <el-table-column
                  v-for="item in tableColumns"
                  :key="item.id"
                  align="center"
                  show-overflow-tooltip
                  :label="item.label"
                  :prop="item.prop"
                  width="auto"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  show-overflow-tooltip
                  label="照片数"
                  prop="pictureNum"
                  width="auto"
                  sortable
                >
                  <template slot-scope="scope">
                    <el-input
                      class="pictureCountInput"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      :class="{
                        editNum: editPicCount && picId === scope.row.id,
                      }"
                      v-model="scope.row.pictureNum"
                      :readonly="!editPicCount || picId !== scope.row.id"
                      @blur="picNumBlur"
                      @change="picNumChange(scope.row.pictureNum)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="150">
                  <template slot-scope="scope">
                    <!-- <el-button @click="handleClick(scope.row)" type="text" size="small" class="greenBtn">查看</el-button> -->
                    <el-button
                      @click="
                        editItemPicCount(scope.row.id, scope.row.pictureNum)
                      "
                      type="text"
                      size="small"
                      class="greenBtn"
                      >修改照片数</el-button
                    >
                    <el-button
                      @click="handleClick(scope.row)"
                      type="text"
                      size="small"
                      class="redBtn"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, prev, pager, next, sizes, jumper"
              :total="totalPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </data-out-div>
    <el-dialog
      class="newAddDevice"
      title="新增航线设备"
      center
      :visible.sync="newAddShow"
      v-drag
      width="50%"
    >
      <div style="width: 100%; border-right: 1px solid rgba(73, 92, 106, 0.2)">
        <el-row style="width: 100%" class="top-row">
          <div style="width: auto" class="dialog_typeDiv">
            <el-form
              :inline="true"
              class="demo-form-inline"
              label-width="80px"
              :rules="rules"
              ref="form"
              :model="newDeviceForm"
            >
              <el-form-item label="设备类型" prop="newDeviceType">
                <el-select v-model="newDeviceForm.newDeviceType">
                  <el-option
                    v-for="item in deviceTypeArr"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                    style="width: 180px"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="设备名称" prop="newDeviceName">
                <div class="dialog_type">
                  <el-input
                    v-model="newDeviceForm.newDeviceName"
                    placeholder="请输入"
                    style="width: 180px"
                    clearable
                  ></el-input>
                </div>
              </el-form-item>
              <el-button class="dialog_typeBtn" @click="newAddSearch('form')"
                >查询</el-button
              >
            </el-form>
          </div>
        </el-row>
        <el-row style="width: 100%" class="bottom-row">
          <div style="width: 100%">
            <el-table
              resizable
              :header-cell-style="{
                background: '#FAFAFA',
                fontWeight: 400,
                color: '#259C9C',
                fontSize: '14px',
                fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN',
              }"
              highlight-current-row
              :data="newtableData2"
              stripe
              border
              row-key="id"
              max-height="450px"
              height="450px"
              tooltip-effect="light"
              :row-style="{ height: '40px' }"
              :cell-style="{ padding: '0.505%' }"
              @row-click="handleDeviceSelection"
              style="width: 100%"
            >
              <el-table-column label width="45">
                <template slot-scope="scope">
                  <el-radio :label="scope.row.sbmc" v-model="radioId"
                    >&nbsp;</el-radio
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="sbbm"
                label="设备编号"
                align="center"
                show-overflow-tooltip
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="sbmc"
                align="center"
                :label="queryDeviceName"
                show-overflow-tooltip
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="ssdsmc"
                align="center"
                label="所属地市名称"
                show-overflow-tooltip
                width="auto"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goDispatch">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="newNetAddDevice"
      title="外网新增航线设备"
      center
      :visible.sync="newNewAddShow"
      v-drag
      width="40%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: inline-flex;
          justify-content: center;
        "
      >
        <el-form
          ref="newForm"
          :rules="newRules"
          :model="netNewDeviceForm"
          label-width="100px"
          style="height: 300px; width: 80%"
        >
          <el-form-item label="设备编号" prop="equipId">
            <el-input
              v-model="netNewDeviceForm.equipId"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="equipName">
            <el-input
              v-model="netNewDeviceForm.equipName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="equipType">
            <el-select v-model="netNewDeviceForm.equipType" @change="handleEquipTypeChange" style="width: 100%">
              <el-option
                v-for="item in deviceTypeArr"
                :key="item.id"
                :label="item.label"
                :value="item.value"
                style="width: 180px"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="经度">
                <el-input
                  v-model="netNewDeviceForm.actualLongitude"
                  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                  placeholder="请输入"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度">
                <el-input
                  v-model="netNewDeviceForm.actualLatitude"
                  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                  placeholder="请输入"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="照片数">
                <el-input
                  v-model.number="netNewDeviceForm.pictureNum"
                  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                  placeholder="请输入"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goNetDispatch('newForm')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import DataOutDiv from "@/components/dataLedger/DataOutDiv";
import api_uav from "@utils/api/api_uav";
import Sortable from "sortablejs";
import "@/utils/dialogdrag";
export default {
  name: "PictureManager",
  components: {
    DataOutDiv,
  },
  data() {
    return {
      rules: {
        newDeviceName: [{ required: true, message: "", trigger: "blur" }],
        newDeviceType: [{ required: true, message: "", trigger: "blur" }],
      },
      newRules: {
        equipId: [{ required: true, message: "请输入设备ID", trigger: "blur" }],
        equipType: [{ required: true, message: "请选择设备类型", trigger: "change" }],
        equipName: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
      },
      deleteNum: 0,
      radioId: "",
      newtableData2: [],
      editPicCount: false,
      deviceName: "",
      newDeviceForm: {
        newDeviceName: "",
        newDeviceType: "",
      },
      netNewDeviceForm: {
        airLineId: "",
        equipName: "",
        equipType: "",
        pictureNum: "",
        equipId: "",
        actualLongitude: "",
        actualLatitude: "",
      },
      totalPage: 0,
      deviceType: "",
      queryDeviceName: "设备名称",
      deviceTypeArr: [
        {
          label: "定点",
          value: 0,
        },
        {
          label: "箱式变压器",
          value: 1,
        },
        {
          label: "柱上变压器",
          value: 2,
        },
        {
          label: "开闭所",
          value: 3,
        },
        {
          label: "环网柜",
          value: 4,
        },
        {
          label: "分支箱",
          value: 5,
        },
        {
          label: "配电室",
          value: 6,
        },
        {
          label: "杆塔",
          value: 7,
        },
        {
          label: "开关",
          value: 8,
        },
        {
          label: "其他",
          value: 9,
        },
      ],
      currentPage: 1,
      pageSize: 10,
      newAddShow: false,
      newNewAddShow: false,
      tableColumns: [
        {
          label: "顺序号",
          prop: "sort",
        },
        // {
        //     label:'路线名称',
        //     prop:'equipName',
        // },
        {
          label: "设备名称",
          prop: "equipName",
        },
        {
          label: "设备类型",
          prop: "equipType",
        },
      ],
      tableData: [],
      curLineId: "",
      recordIds: [],
      recordSortPage: [],
      picId: 0,
      filterText: "",
      treeData: [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1",
                },
                {
                  id: 10,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectArr: [],
      newDeviceSel: [],

      // }
    };
  },
  props: {
    firstId: {
      type: String,
      default: null,
    },
  },
  mounted() {
    // this.queryInfo()
    this.$nextTick(() => {
      this.rowDrop();
    });
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    deviceApiFunc(url, params) {
      this.axios.get(url, { params: params }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then((res) => {
        if (res.status === 200) {
          this.newtableData2 = res.data;
          if (this.newDeviceForm.newDeviceType === 7) {
            this.queryDeviceName = "杆塔编号";
            this.newtableData2.forEach((item) => {
              item.sbmc = item.gtbh;
            });
          } else if (
            this.newDeviceForm.newDeviceType !== 2 &&
            this.newDeviceForm.newDeviceType !== 8
          ) {
            this.queryDeviceName = "变电站名称";
            this.newtableData2.forEach((item) => {
              item.sbmc = item.bdzmc;
            });
          } else {
            this.queryDeviceName = "设备名称";
          }
        } else {
          this.$message.error(res.data);
        }
      });
    },
    newAddSearch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if(!this.newDeviceForm.newDeviceName || !this.newDeviceForm.newDeviceType){
          //     this.$message.warning("请先输入查询条件")
          //     return
          // }
          let url = NEWADD_AIR_DEVICE_URL;
          let params = {
            sbmc: this.newDeviceForm.newDeviceName,
          };
          switch (this.newDeviceForm.newDeviceType) {
            case 2: //柱上变压器
              url += "/cst/pmxl/dws/get_t_sb_zwyc_zsbyq";
              break;
            case 7: //杆塔
              url += "/cst/pmxl/dws/get_t_sb_zwyc_gt";
              break;
            case 8: //隔离开关
              url += "/cst/pmxl/dws/get_t_sb_znyc_glkg";
              break;
            default: //其余都走电站
              url += "/cst/pmxl/dws/get_t_sb_znyc_dz";
              break;
          }
          this.deviceApiFunc(url, params);
        } else {
          // console.log('error submit!!');
          this.$message.warning("请先输入查询条件");
          return false;
        }
      });
    },
    handleDeviceSelection(row) {
      if (row.sbmc) {
        this.radioId = row.sbmc;
      }
      this.newDeviceSel = row;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    picNumBlur(pictureNum) {
      this.editPicCount = false;
    },
    picNumChange(pictureNum) {
      let params = {
        id: this.picId,
        pictureNum: pictureNum,
      };
      this.axios
        .put("/api/airline/equip/updatePictureNum", params, {
          headers: {
              'Authorization': sessionStorage.getItem('token'),
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.queryInfo();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    editItemPicCount(id, num) {
      this.picId = id;
      this.editPicCount = true;
    },
    goSearch() {
      this.currentPage = 1;
      this.queryInfo();
    },
    newSort(flag) {
      let oldIds = this.recordIds.map((x) => x.id);
      let newIds = this.tableData.map((x) => x.id);
      // console.log(newIds)
      // if(this.oldRecordIds.length > 0){
      //     let oldRecord = this.oldRecordIds.map(x => x.id)
      // console.log(oldRecord)
      // let diffIds = newIds.filter(x => {
      //     return oldRecord.indexOf(x) < 0
      // })[0]
      // console.log(diffIds)
      // // console.log(diffIds)
      //     newIds.splice(newIds.indexOf(diffIds),1)
      //     newIds.push(diffIds)
      // }
      // console.log(rIds)
      // console.log(this.tableData)
      let sortArrs = [];
      let allData = [];
      let params = {
        ids: this.curLineId,
        equipName: this.deviceName,
        equipType: this.deviceType,
        // currentPage:this.currentPage,
        // pageSize:this.pageSize,
      };
      // console.log(this.tableData)
      oldIds.forEach((item, index) => {
        if (newIds.indexOf(item) > -1) {
          sortArrs.push({
            id: item,
            // sort:newIds.indexOf(item) + 1 + (this.tableData.filter(x => x.id === item)[0].curNum - 1) * this.pageSize
            sort:
              newIds.indexOf(item) + 1 + (this.currentPage - 1) * this.pageSize,
          });
        }
      });
      // api_uav.queryAllLineDeviceInfo().then(res => {
      //     if(res.data.code === 200){
      //         allData = res.data.data
      //         allData = allData.map(x => {
      //             return {
      //                 id:x.id,
      //                 sort:x.sort
      //             }
      //         })
      //         let num = this.pageSize > this.totalPage? this.totalPage : this.pageSize
      //         for(let i = 0; i < num; i++){
      //             allData[i] = sortArrs[i]
      //         }
      this.axios.put("/api/airline/equip/updateSort", sortArrs, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then((res) => {
        if (res.data.code === 200) {
          this.queryInfo();
          // if(flag !== 2){
          this.$message.success(res.data.msg);
          // }
        } else {
          this.$message.error(res.data.msg);
        }
      });
      //     }else{
      //         this.$message.error(res.data.msg)
      //     }
      // })
    },
    async resetSort(flag) {
      if (flag === 1 || flag === 2) {
        this.queryInfo(flag);
        // this.recordSortPage.push(this.currentPage)
        // console.log(flag)
        if (flag === 2) {
          // this.recordSortPage.push(this.currentPage)
        }
        // await this.queryInfo()
        // await this.newSort()
      } else {
        this.newSort();
      }
    },
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const that = this;
      Sortable.create(tbody, {
        // 结束拖拽后的回调函数
        onEnd({ newIndex, oldIndex }) {
          console.log("拖动了行，当前序号：" + newIndex);
          const currentRow = that.tableData.splice(oldIndex, 1)[0];
          that.tableData.splice(newIndex, 0, currentRow);
        },
      });
    },
    searchChange() {
      if (!this.deviceName) {
        this.currentPage = 1;
        this.queryInfo();
      }
    },
    sendLineId(id) {
      this.curLineId = id;
      this.queryInfo();
    },
    handleDeleteMost() {
      if (this.selectArr.length < 1) {
        this.$message.warning("请先勾选要删除的数据");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.selectArr.map((x) => x.id).join(",");
          api_uav.deleteLineDeviceInfo(ids).then((res) => {
            if (res.data.code === 200) {
              this.$refs.multipleTable.clearSelection();
              this.$message.success(res.data.msg);
              // this.currentPage = 1
              this.queryInfo();
              // this.resetSort(1)
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
      return false;
    },
    handleClick(rows) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api_uav.deleteLineDeviceInfo(rows.id).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              // this.currentPage = 1
              this.queryInfo();
              this.deleteNum = this.currentPage;
              // this.resetSort(1)
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
      return false;
    },
    resetSearch() {
      this.currentPage = 1;
      this.deviceName = "";
      this.deviceType = "";
      this.queryInfo();
    },
    handleEquipTypeChange(val){
      if (val === 0) {
        this.netNewDeviceForm.pictureNum = 0
      } else {
        this.netNewDeviceForm.pictureNum = 1
      }
    },
    goNetDispatch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            let params = {
            airLineId: this.curLineId,
            equipName: this.netNewDeviceForm.equipName,
            equipType: this.netNewDeviceForm.equipType,
            pictureNum: this.netNewDeviceForm.pictureNum,
            equipId: this.netNewDeviceForm.equipId,
            actualLongitude: this.netNewDeviceForm.actualLongitude,
            actualLatitude: this.netNewDeviceForm.actualLatitude,
        }
        api_uav.addLineDeviceInfo(params).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              this.newNewAddShow = false;
              this.netNewDeviceForm = {
                  airLineId: "",
                  equipName: "",
                  equipType: "",
                  pictureNum: "",
                  equipId: "",
                  actualLongitude: "",
                  actualLatitude: "",
              },
              this.newtableData2 = [];
              this.queryInfo();
            } else {
              this.$message.error(res.data.msg);
            }
        });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goDispatch() {
      if (!this.radioId) {
        this.$message.warning("请先选择要添加的设备");
        return;
      }
      let params = {
        airLineId: this.curLineId,
        equipName: this.radioId,
        equipType: this.newDeviceForm.newDeviceType,
        pictureNum: 3,
        equipId: this.newDeviceSel.obj_id,
      };
      api_uav.addLineDeviceInfo(params).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.newAddShow = false;
          this.newDeviceForm = {
            newDeviceName: "",
            newDeviceType: "",
          };
          this.newtableData2 = [];
          this.queryInfo();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSelectionChange(val) {
      this.selectArr = val;
      // console.log(this.selectArr)
    },
    handleNewAdd() {
      this.newAddShow = true;
      this.radioId = "";
    },
    handleNetNewAdd() {
      this.newNewAddShow = true;
    },
    queryInfo(flag) {
      let params = {
        ids: this.curLineId,
        equipName: this.deviceName,
        equipType: this.deviceType,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      api_uav.queryLineDeviceInfo(params).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.rows;
          this.tableData = this.tableData.map((x) => {
            x.equipType = this.deviceTypeArr.filter(
              (y) => y.value === Number(x.equipType)
            )[0].label;
            x.pictureNum = x.pictureNum ? x.pictureNum : 0;
            x.curNum = this.currentPage;
            return x;
          });
          this.recordIds = JSON.parse(JSON.stringify(this.tableData));
          this.totalPage = res.data.total;
          if (flag === 1) {
            this.newSort();
          }
          if (flag === 2) {
            this.newSort(2);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.queryInfo();
    },
    handleCurrentChange(val) {
      // console.log(Math.ceil(this.totalPage / this.pageSize))
      let tPage = Math.ceil(this.totalPage / this.pageSize);
      this.currentPage = val;
      // if(this.deleteNum !== 0 && (this.deleteNum < this.currentPage)){
      // if(this.totalPage)
      // console.log(tPage - this.deleteNum)
      // console.log(this.recordSortPage.length)
      // if((tPage - this.deleteNum) < this.recordSortPage.length){
      // this.resetSort(2)
      // }else{
      //     this.queryInfo()
      // }
      // }else{
      // this.queryInfo()
      // }
      // this.resetSort(2)
      this.queryInfo();
    },
  },
};
</script>
<style lang="stylus">
.el-select-dropdown__item
    width: 100% !important
</style>
<style lang="stylus" scoped>
>>>.el-form-item__error
    display:none
>>>.el-form-item
    margin-bottom:0 !important
>>>.el-dialog__body
    padding 25px 25px 10px !important
>>>.el-table--border
    border none
>>>.el-table__empty-block
    height 43vh !important
@media screen and (max-width: 1400px)
    .top_first
    .deviceType
        font-size:12px
//     .dialog_typeDiv
//         width: 70% !important
//     .dialog_type
//         display: block !important
//         label
//             margin-left:0 !important
//     .dialog_typeBtn
//         position absolute
//         top 20px
//         right 50px
//     >>>.el-input
//     >>>.el-input__inner
//         height: 25px !important
//     >>>.el-input__suffix
//         height: 27px !important
//     >>>.el-button
//         height: 25px !important
.pictureManager
    width: 100%
    height: 100%
    .editNum
        >>>.el-input__inner
            border 1px solid rgba(255,255,255,0.5) !important
            background: white !important
    .pictureCountInput
        >>>.el-input__inner
            text-align: center
            border 0px solid rgba(255,255,255,0.5) !important
            background: transparent
    .newAddDevice
        >>>.el-dialog
            margin-top: 12vh !important
            min-width: 800px
            .dialog_type
                display:inline-block
            .el-row
                border 1px solid rgba(73,92,106,0.2);
                border-right: none
            .top-row
                padding-left: 15px
                display: flex
                justify-content: flex-start
                align-items: center
                border-bottom none
                height: 70px
                .el-input
                    width: 180px
                .el-button
                    margin-left: 30px
                    width: 70px
                    height: 38px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                    // background: #259C9C;
                    border 1px solid #259C9C
                    color: #259C9C
        .el-col-7
        .el-col-6
        .el-col-12
            // position relative
            border-right: 1px solid rgba(73,92,106,0.2);
            display: flex
            justify-content: center
            align-items: center
        .bottom-row
            .el-col-7
            .el-col-6
            .el-col-12
                align-items: flex-start
                height: 500px
            .filter-tree
                height: 500px
                overflow-y: hidden
            .pagination
                // position absolute
                // right 10px
                float right
                margin-top : 5px
                margin-right -8px
        .el-dialog__header
            text-align: left
        .el-dialog__body
            padding 25px 25px 10px !important
            display: flex
            justify-content: center
            align-items: center
        .el-input
        .el-input__inner
        .el-input__suffix
            height: 32px
        .el-input__icon
            line-height: 32px
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
    .rightContent
        width: 100%
        height: 100%
        position: relative
        .top_first
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 7%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
            .deviceType
                >>>.el-input
                    width: 100%
                    height: 36px
        .top_first
            padding:  2% 0 2% 0
            .Btn
                // float: right
                // text-align: right
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
        .middle_title
            height: 20px
            position: relative
            left -6px
            .inner
                div
                    display: inline-block
                .left_icon
                    width: 4px;
                    height: 20px;
                    background: #1F9999;
                    opacity: 1;
                .text
                    position: absolute
                    padding-left: 10px
                    padding-top: -5px
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #1F9999;
                    line-height: 20px;
                >>>.el-button
                    position: absolute
                    left: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
                .newAdd
                    position: absolute
                    left: 25px
                .reSort
                    position: absolute
                    left:105px
                    bottom:0px
                    background: white;
                    color: #259C9C
                    border: 1px solid #259C9C;
                .netNewAdd
                    position: absolute
                    color: #259C9C;
                    border: 1px solid #259C9C;
                    background: rgba(37,156,156,0.1);
                .editCount
                    position: absolute
                    left: 195px
                    bottom:0px
                    background: #6BB8B9
                    color: white
        .bottom_title
            height: 20px
            position: relative
            padding-left: 40px
            .inner
                div
                    display: inline-block
                .left_icon
                    width: 4px;
                    height: 20px;
                    background: #1F9999;
                    opacity: 1;
                .text
                    position: absolute
                    padding-left: 10px
                    padding-top: -5px
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #1F9999;
                    line-height: 20px;
                >>>.el-button
                    position: absolute
                    right: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
                .reSort
                    background: white;
                    color: #259C9C
                    border: 1px solid #259C9C;
        .bottom
            width: 100%
            margin-top: 1%
            // height: calc(91% - 36px)
            height: 74.9%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 350px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 350px);
        .pagination
            position: absolute
            width: 100%
            height: 60px
            bottom: 0
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
.newNetAddDevice
    >>>.el-form-item
        margin-bottom: 25px !important
</style>
