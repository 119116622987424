<template>
    <div class="patroltask">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>用户名</label>
                        <el-input v-model="userName" placeholder="请输入名称" clearable @change="searchChange" @keydown.enter.native="goSearch"></el-input>
                    </div>
                    <!-- <div class="deviceType">
                        <label>所属无人机机组</label>
                        <el-select v-model="deptName">
                            <el-option v-for="item in deptArrs" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div> -->
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset" @click="resetSearch">重置</el-button>
                    </div>
                </div>
                <div class="top_second">
                    <el-button @click="handleNewAdd">新增</el-button>
                    <el-button style="margin-left:90px;border:1px solid red;color:red;background:white" @click="handleDeleteMost">批量删除</el-button>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            ref="multipleTable"
                            resizable
                            highlight-current-row
                            :data="tableData"
                            row-key="userId"
                            style="width:100%;height:100%"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            max-height="calc(100vh - 250px)"
                            :row-style="{height: '40px'}"
                            :cell-style="{padding: '0.45%'}"
                            tooltip-effect="light"
                            @selection-change="handleSelectionChange"
                            >
                            <el-table-column
                                show-overflow-tooltip
                                type="selection"
                                :reserve-selection="true" 
                                width="55">
                            </el-table-column>
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                width="auto"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                align="center"
                                label="操作"
                                width="200">
                                <template slot-scope="scope">
                                    <el-button @click="handleEditInfo(scope.row)" type="text" size="small" class="greenBtn">修改信息</el-button>
                                    <el-button @click="handleEditPass(scope.row)" type="text" size="small" class="greenBtn">修改密码</el-button>
                                    <el-button @click="handleDelete(scope.row)" type="text" size="small" class="redBtn">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :title="dialogTitle"
            center
            v-drag
            :visible.sync="newAddShow"
            width="40%">
            <div style="width:60%">
                <el-form ref="form" :model="newAddForm" label-width="80px" :rules="rules">
                    <div v-if="addOrEdit || infoOrPass">
                        <el-form-item label="用户" prop="userName">
                            <el-input v-model="newAddForm.userName" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="用户昵称">
                            <el-input v-model="newAddForm.nickName" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="phonenumber">
                            <el-input v-model.number="newAddForm.phonenumber" onkeyup="this.value=this.value.replace(/\D/g,'')" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="账号状态" v-if="!addOrEdit">
                            <el-select v-model="newAddForm.status" style="width:50%">
                                <el-option label="正常" value="0"></el-option>
                                <el-option label="停用" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item label="密码" v-if="addOrEdit || !infoOrPass" prop="password">
                        <el-input v-model="newAddForm.password" type="password" placeholder="请输入" show-password clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit('form')">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
export default {
    name:'TaskManager',
    data(){
        const validatePhone = (rule,value,callback)=>{
            //使用正则表达式进行验证手机号码
            if (!(/^1[3456789]\d{9}$/.test(value))){
                callback(new Error('请输入正确的手机号'));
            }else {
                callback()
            }
        };
        const validatePass = (rule, value, callback) => {
            if (!(/(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{6,20}$/.test(value))) {
                callback(new Error('密码至少6位,必须包含数字、字母或者特殊字符的其中两种'));
            } else {
                callback()
            }
        }
        return{
            rules:{
                phonenumber: [
                   { validator: validatePhone, trigger: 'blur' },
                   { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                userName: [
                   { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password: [
                   { required: true, message: '请输入密码', trigger: 'blur' },
                   { validator: validatePass, trigger: 'blur' },
                ],
            },
            userName:'',
            deptName:'',
            currentPage: 1,
            newAddShow:false,
            newAddForm:{
                userName:'',
                nickName:'',
                phonenumber:'',
                password:'',
                status:'',
            },
            totalPage:0,
            pageSize:10,
            deptArrs:[],
            tableColumns:[
                {
                    label:'用户名',
                    prop:'userName',
                },
                {
                    label:'昵称',
                    prop:'nickName',
                },
                {
                    label:'手机号',
                    prop:'phonenumber',
                },
                {
                    label:'账号状态',
                    prop:'statusText',
                },
                {
                    label:'创建时间',
                    prop:'createTime',
                },
            ],
            tableData: [],
            addOrEdit:true,
            infoOrPass:true,
            dialogTitle:'新增APP用户',
            recordOptions:1,
            selectArr:[],
        }
    },
    mounted(){
        this.queryUserListInfo()
    },
    methods:{
        goSearch(){
            this.currentPage = 1
            this.queryUserListInfo()
        },
        handleNewAdd(){
            this.newAddShow = true
            this.addOrEdit = true
            this.newAddForm = {
                userName:'',
                nickName:'',
                phonenumber:'',
                password:'',
                status:'',
            }
            this.dialogTitle = '新增APP用户'
            this.recordOptions = 1
            
        },
        submit(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = {}
                switch (this.recordOptions) {
                    case 1:  //新增
                        params = {
                            userName:this.newAddForm.userName,
                            nickName:this.newAddForm.nickName,
                            phonenumber:this.newAddForm.phonenumber,
                            password:this.newAddForm.password,
                            status:this.newAddForm.status,
                            userId:this.newAddForm.userId
                        }
                        if(this.addOrEdit){
                            params.status = 0
                        }
                        api_uav.addNewAppUser(params).then(res => {
                            if(res.data.code === 200){
                                this.newAddForm = {
                                    userName:'',
                                    nickName:'',
                                    phonenumber:'',
                                    password:'',
                                    status:'',
                                },
                                this.newAddShow = false
                                this.queryUserListInfo()
                                this.$message.success(res.data.msg)
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })
                        break;
                     case 2:  //修改信息
                            params = {
                                userName:this.newAddForm.userName,
                                nickName:this.newAddForm.nickName,
                                phonenumber:this.newAddForm.phonenumber,
                                status:this.newAddForm.status,
                                userId:this.newAddForm.userId
                            }
                            this.axios.put('/api/system/user',params, {
                                headers: {
                                    'Authorization': sessionStorage.getItem('token'),
                                },
                            }).then(res => {
                            if(res.data.code === 200){
                                this.newAddForm = {
                                    userName:'',
                                    nickName:'',
                                    phonenumber:'',
                                    password:'',
                                    status:'',
                                },
                                this.newAddShow = false
                                this.queryUserListInfo()
                                this.$message.success(res.data.msg)
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })
                        break;
                    case 3:  //修改密码
                        params = {
                            password:this.newAddForm.password,
                            userId:this.newAddForm.userId
                        }
                        this.axios.put('/api/system/user/resetPwd',params, {
                            headers: {
                                'Authorization': sessionStorage.getItem('token'),
                            },
                        }).then(res => {
                            if(res.data.code === 200){
                                this.newAddForm = {
                                    userName:'',
                                    nickName:'',
                                    phonenumber:'',
                                    password:'',
                                    status:'',
                                },
                                this.newAddShow = false
                                this.queryUserListInfo()
                                this.$message.success(res.data.msg)
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })
                        break;
                    default:
                        break;
                }
            } else {
                console.log('error submit!!');
                return false;
            }
            });
            
        },
        searchChange(){
            if(!this.userName){
                this.currentPage = 1
                this.queryUserListInfo()
            }
        },
        resetSearch(){
            this.currentPage = 1
            this.userName = ''
            this.deptName = ''
            this.queryUserListInfo()
        },
        queryUserListInfo(){
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                userName: this.userName,
                deptName: this.deptName
            }
            api_uav.queryAppUser(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData = this.tableData.map(x => {
                        x.statusText = +x.status === 0? '正常':'停用' 
                        return x
                    })
                    this.totalPage = res.data.total
                    // this.$message.success(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.currentPage = 1
            this.pageSize = val
            this.queryUserListInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryUserListInfo()
        },
        handleEditInfo(item){
            // console.log(item)
            this.addOrEdit = false
            this.newAddShow = true
            this.newAddForm = JSON.parse(JSON.stringify(item))
            this.infoOrPass = true
            this.dialogTitle = '修改信息'
            this.recordOptions = 2
        },
        handleEditPass(item){
            this.addOrEdit = false
            this.newAddShow = true
            this.newAddForm = JSON.parse(JSON.stringify(item))
            this.infoOrPass = false
            this.dialogTitle = '修改密码'
            this.recordOptions = 3
        },
        handleSelectionChange(val){
            this.selectArr = val
        },
        handleDeleteMost(){
            if(this.selectArr.length < 1){
                this.$message.warning('请先勾选要删除的数据')
                return 
            }
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = this.selectArr.map(x => x.userId).join(',')
                api_uav.deleteAppUser(ids).then(res => {
                    if(res.data.code === 200){
                        this.$refs.multipleTable.clearSelection();
                        this.$message.success(res.data.msg)
                        this.queryUserListInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
        },
        handleDelete(item){
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // let ids = this.selectArr.map(x => x.userId).join(',')
                api_uav.deleteAppUser(item.userId).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.queryUserListInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
            
        }
    }
}
</script>
<style lang="stylus" scoped>
.patroltask
    width: 100%
    height: 100%
    >>>.el-dialog
        .el-dialog__header
            text-align: left
        .el-dialog__body
            margin 30px 0 0 0
            // height: 10vh
            display: flex
            justify-content: center
            align-items: center
        .el-input
        .el-input__inner
        .el-input__suffix
            height: 32px
        .el-input__icon
            line-height: 32px
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                padding-left: 2px
                width: 50%
                display: inline-block
                label
                    margin: 0 25px
                >>>.el-input
                    width: 40%
                    height: 36px
                >>>.el-select
                    width: 50%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:45px
            margin: 10px 0
            >>>.el-button
                    position: absolute
                    left: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
            .seeVideo
                border: 1px solid #3BCEBC !important 
                background: white;
                color: #3BCEBC;
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                position:relative
                left: -15%
                width: 30%
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
            .deviceType
                >>>.el-input
                    width: 60%
                    height: 36px
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:110px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 330px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>