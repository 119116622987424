<template>
    <div class="patroltask">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>名称</label>
                        <el-input v-model="uavXh" placeholder="请输入名称" clearable @keydown.enter.native="goSearch" @change="seachChange"></el-input>
                    </div>
                    <!-- <div class="deviceType"> -->
                        <!-- <label>创建时间</label> -->
                        <!-- <el-input v-model="flyState" placeholder="请输入" clearable  @keydown.enter.native="goSearch" @change="seachChange"></el-input> -->
                        <!-- <el-select v-model="flyState">
                            <el-option label="是" value="true"></el-option>
                            <el-option label="否" value="false"></el-option>
                        </el-select> -->
                    <!-- </div> -->
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset" @click="resetSearch">重置</el-button>
                    </div>
                </div>
                <div class="top_second">
                    <el-button @click="handleNewAdd">新增</el-button>
                    <el-button style="margin-left:90px;border:1px solid red;color:red;background:white" @click="handleDeleteMost">批量删除</el-button>
                    <el-button class="seeVideo" style="margin-left:180px" @click="checkVideoSelect">查看直播</el-button>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            ref="multipleTable"
                            resizable
                            highlight-current-row
                            :data="tableData"
                            row-key="id"
                            style="width:100%;height:100%"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            max-height="calc(100vh - 250px)"
                            :row-style="{height: '50px'}"
                            :cell-style="{padding: '0.505%'}"
                            tooltip-effect="light"
                            @selection-change="handleSelectionChange"
                            >
                            <el-table-column
                                show-overflow-tooltip
                                type="selection"
                                :reserve-selection="true" 
                                width="55">
                            </el-table-column>
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                min-width="120"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                align="center"
                                label="操作"
                                width="170">
                                <template slot-scope="scope">
                                    <el-button @click="handleClickLine(scope.row)" type="text" size="small" class="greenBtn">修改</el-button>
                                    <el-button @click="handleClickDetail(scope.row)" type="text" size="small" class="redBtn">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-count="5"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            class="newAdd"
            :title="dialogTitle"
            center
            v-drag
            :visible.sync="newAddShow"
            width="30%">
            <div style="width:80%">
                <el-form ref="form" :model="newAddForm" label-width="100px">
                    <el-form-item label="名称">
                        <el-input v-model="newAddForm.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="直播地址">
                        <el-input v-model="newAddForm.url" placeholder="请输入"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="厂家"> -->
                        <!-- <el-select v-model="newAddForm.equipType">
                            <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select> -->
                        <!-- <el-input v-model="newAddForm.cj" placeholder="请输入"></el-input> -->
                        <!-- <el-select v-model="newAddForm.cj">
                            <el-option label="道通无人机" value="道通"></el-option>
                            <el-option label="大疆无人机" value="大疆"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="机翼类型">
                        <el-input v-model="newAddForm.jylx" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="购买日期"> -->
                        <!-- <el-input v-model="newAddForm.gmrq" placeholder="请输入"></el-input> -->
                        <!-- <el-date-picker
                            v-model="newAddForm.gmrq"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="维护单位">
                        <el-input v-model="newAddForm.whdw" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="飞行状态"> -->
                        <!-- <el-select v-model="newAddForm.fxzt">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select> -->
                        <!-- <el-input v-model="newAddForm.fxzt" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="序列号">
                        <el-input v-model="newAddForm.xlh" placeholder="请输入"></el-input>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goDispatch">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            class="videoDialog"
            title="视频直播"
            :close-on-click-modal="false"
            left
            v-drag
            :class="'innerDialog-'+this.videoCount"
            :visible.sync="newAddShow1"
            width="95%">
            <template slot="title">
                <div class="top">
                    视频播放数
                    <el-select v-model="videoCount" @change="selectChange">
                        <el-option label="1" :value="1" :disabled=" videoList.length < 1 "></el-option>
                        <el-option label="2" :value="2" :disabled=" videoList.length < 2 "></el-option>
                        <el-option label="4" :value="4" :disabled=" videoList.length < 4 "></el-option>
                    </el-select>
                </div>
            </template>
            <div style="width:100%;height:100%">
                <div class="bottom">
                    <div class="inner" :class="'inner-'+this.videoCount">
                        <iframe v-for="(item,index) in newVideoList" :key="item.id" :class="'video-'+index" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" :src="item"></iframe>
                        <!-- <iframe id="iframe-one" name="iframe-one" src="https://mudu.tv/live/watch/ogd9jkeo" width="100%" height="200" scrolling="no"
                        frameborder="0"></iframe> -->
                        <!-- <iframe id="mapFrame" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" name="mapFrame" scrolling="no" frameborder="0" src="https://mudu.tv/live/watch/ogd9jkeo"></iframe> -->
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange1"
                        @current-change="handleCurrentChange1"
                        :current-page="currentPage1"
                        :page-sizes="[1,2,4]"
                        :pager-count="5"
                        :page-size="videoCount"
                        layout="total,  prev, pager, next"
                        :total="totalPage1">
                    </el-pagination>
                </div>
                <!-- <el-button type="primary" @click="goDispatch">确定</el-button> -->
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
export default {
    name:'TaskManager',
    data(){
        return{
            dialogTitle:'新增直播',
            currentPage1:1,
            totalPage1:0,
            newAddShow:false,
            newAddShow1:false,
            videoCount:4,
            videoList:[],
            newVideoList:[],
            uavXh:'',
            flyState:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            newAddForm:{
                name:'',
                url:'',
            },
            tableColumns:[
                {
                    label:'名称',
                    prop:'name',
                },
                {
                    label:'视频地址',
                    prop:'url',
                },
                {
                    label:'创建时间',
                    prop:'createTime',
                },
            ],
            tableData: [],
            flag:1,
            videoSelectArr:[],
        }
    },
    mounted(){
        this.queryVideoInfo()
    },
    methods:{
        selectChange(){
            // if(val > this.videoList.length){
            //     this.$message.warning(`您当前只选择了${this.videoList.length}个直播`)
            // }else{
                this.currentPage1 = 1
                this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)
            // }
            
        },
        checkVideoSelect(){
            if(this.videoSelectArr.length > 0){
                this.currentPage1 = 1
                this.newAddShow1 = true
                this.newVideoList = this.videoList
                if(this.videoSelectArr.length % 4 === 0){
                    this.videoCount = 4
                }else if(this.videoSelectArr.length % 2 === 0){
                    this.videoCount = 2
                }else{
                    this.videoCount = 1
                }
                this.newVideoList = this.newVideoList.slice( 0, this.videoCount)
            }else{
                this.$message.warning('请先勾选直播')
            }
        },  
        handleSelectionChange(val){
            this.videoSelectArr = val
            this.videoList = this.videoSelectArr.map(x => x.url)
            this.totalPage1 = this.videoSelectArr.length
        },
        goSearch(){
            this.currentPage1 = 1 
            this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)
            this.currentPage = 1
            this.queryVideoInfo()
        },
        handleNewAdd(){
            this.dialogTitle = '新增直播'
            this.newAddShow = true
            this.newAddForm = {
                name:'',
                url:'',
            }
        },
        goDispatch(){
            let params = this.newAddForm
            if(this.flag === 2){
                this.$refs.multipleTable.clearSelection();
                this.axios.put('/api/vedio/info',params, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.queryVideoInfo()
                        this.newAddShow = false
                        this.newAddForm = {
                            name:'',
                            url:'',
                        }
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }else{
                api_uav.addVideoInfo(params).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.queryVideoInfo()
                        this.newAddShow = false
                        this.newAddForm = {
                            name:'',
                            url:'',
                        }
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        seachChange(){
            if(!this.uavXh || !this.flyState){
                this.queryVideoInfo()
            }
        },
        resetSearch(){
            this.currentPage = 1
            this.currentPage1 = 1 
            this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)
            this.uavXh = ''
            this.flyState = ''
            this.queryVideoInfo()
        },
        queryVideoInfo(){
            let params = {
                currentPage : this.currentPage,
                pageSize : this.pageSize
            }
            if(this.uavXh){
                params.name = this.uavXh
            }
            if(this.flyState){
                params.createTime = this.flyState
            }
            api_uav.queryVideoInfo(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.totalPage = res.data.total
                    // this.$message.success(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange1(val){
            // this.pageSize = val
            // this.currentPage = 1
            // this.queryVideoInfo()
        },
        handleCurrentChange1(val){
            this.currentPage1 = val
            this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)

            // this.currentPage = val
            // this.queryVideoInfo()

        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.currentPage1 = 1 
            this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)
            this.queryVideoInfo()
        },
        handleCurrentChange(val){
            this.currentPage1 = 1 
            this.newVideoList = this.videoList.slice( this.videoCount * (this.currentPage1 - 1), this.currentPage1 * this.videoCount)
            this.currentPage = val
            this.queryVideoInfo()

        },
        handleClickLine(item){
            this.dialogTitle = '修改直播信息'
            this.newAddShow = true
            this.newAddForm = JSON.parse(JSON.stringify(item))
            this.flag = 2
        },
        handleDeleteMost(){
            if(this.videoSelectArr.length < 1){
                this.$message.warning('请先勾选要删除的数据')
                return 
            }
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = this.videoSelectArr.map(x => x.id).join(',')
                api_uav.deleteVideoInfo(ids).then(res => {
                    if(res.data.code === 200){
                        this.$refs.multipleTable.clearSelection();
                        this.$message.success(res.data.msg)
                        this.queryVideoInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
        },
        handleClickDetail(item){
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api_uav.deleteVideoInfo(item.id).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.queryVideoInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
            
            
        }
    }
}
</script>
<style lang="stylus" scoped>
#iframe-one:-webkit-full-screen {width: 100%;height: 100%;}
.patroltask
    width: 100%
    height: 100%
    .newAdd
        >>>.el-dialog
            .el-dialog__body
                height:20vh
                display: flex
                justify-content: center
                align-items: center
            .el-select
            .el-input
                width:100%
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                padding-left: 2px
                width: 50%
                display: inline-block
                label
                    margin: 0 40px
                >>>.el-input
                    width: 40%
                    height: 36px
                >>>.el-select
                    width: 50%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:45px
            margin: 10px 0
            >>>.el-button
                    position: absolute
                    left: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
            .seeVideo
                border: 1px solid #3BCEBC !important 
                background: white;
                color: #3BCEBC;
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                position:relative
                left: -15%
                width: 30%
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
            .deviceType
                >>>.el-input
                    width: 60%
                    height: 36px
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:110px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 330px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 330px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
.videoDialog
    .el-dialog
        margin: 0 auto 20px !important;
        margin-top: 3vh !important
        .el-input
            width:50% !important
        .top
            width: 100%
            // position: absolute
            >>>.el-select
                width: 60px
                .el-input__inner
                    height: 28px
                .el-select__caret
                    position: relative
                    top:5px
                .is-reverse
                    top:-5px !important
    .inner-1
        display: grid
        grid-template-columns: 1fr !important
        grid-template-rows: 1fr !important
    .inner-2
        display: grid
        grid-template-columns:50% 50% !important
        grid-template-rows: 1fr !important
    .inner-4
        display: grid
        grid-template-columns:50% 50% !important
        grid-template-rows:50% 50% !important
    .bottom
        width: 100%
        height: 100%
        .inner
            width: 100%
            height: 100%
            display: grid
            grid-template-columns:50% 50%
            grid-template-rows:50% 50%
            iframe
                width: 100%
                height: 100%
    .pagination
        position: absolute
        right: 10px
        bottom:  2px

.innerDialog-1
    >>>.el-dialog
        width: 95% !important
        margin-top: 2vh !important
    >>>.el-dialog
        width: 95% !important
        .el-dialog__body
            height: 76.5vh !important
.innerDialog-2
    >>>.el-dialog
        width: 95% !important
        margin-top: 2vh !important
    >>>.el-dialog__body
        height: 76.5vh !important
.innerDialog-4
    >>>.el-dialog
        width: 95% !important
        margin-top: 2vh !important
    >>>.el-dialog__body
        height: 76.5vh
</style>