<template>
    <div class="patroltask">
        <div class="top">
            <div class="inner">
                <label class="leftText">选择缺陷日期</label>
                <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <div class="BtnDiv">
                    <el-button class="lineBtn">按航线统计</el-button>
                    <el-button class="flawBtn">按缺陷统计</el-button>
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="inner">

            </div>
        </div>
        <div class="bottom">
            <div class="inner">
                <div class="bottom_left">

                </div>
                <div class="bottom_right">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'PatrolTask',
    data(){
        return{
            dateValue:[]
        }
    }
}
</script>
<style lang="stylus" scoped>
.patroltask
    width: 100%
    height: 100%
    .top
        .inner
            width: 100%
        height: 10%
        display: flex
        align-items: center
        .leftText
            margin: 0px 20px
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
        >>>.el-range-separator
        >>>.el-icon-date
        >>>.el-icon-circle-close
            display: inline-flex
            align-items: center
        >>>.el-range-editor.el-input__inner
            height: 32px
        >>>.el-button
            border-radius: 4px 4px 4px 4px;
            background: #259C9C;
            color: #fff
            height: 32px
            width: 109px
            display: inline-flex
            align-items: center
        .BtnDiv
            display: inline-block
            margin-left: 20px
            .lineBtn
                margin-right: 10px
    .middle
    .bottom
        width: 100%
        height: 43%
        display: flex
        justify-content: center
        .inner
            width: 98%
    .bottom
        margin-top: 15px
        .bottom_left
        .bottom_right
            display: inline-block
            height: 100%
            width: 49.5%
            background: #DAF4ED;
        .inner
            display: inline-flex
            justify-content: space-between
    .middle
        .inner
            background: #DAF4ED;
    
</style>