<template>
    <div class="outDiv">
        <el-row>
            <el-col :span="5">
                <div class="top">
                    <div class="inner">
                        <el-input
                            placeholder="请输入名称"
                            suffix-icon="el-icon-search"
                            v-model="searchName"
                            @keydown.enter.native="goSearch"
                            @change="searchChange"
                            clearable>
                        </el-input>
                        <div class="top_icon" @click="addNewLine">
                            <i class="el-icon-plus"></i>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <div class="taskItem"  v-for="(item,index) in taskArrs" :key="item.id" @click="clickTaskItem(index,item.id)" :class="{'taskActive':currentId === item.id,'editInput':editLineName && currentId === item.id,'readInput':!editLineName || currentId !== item.id}">
                            <el-form label-position="left" :label-width="'44%'">
                                <el-form-item label="航线名称">
                                    <el-tooltip :content="item.name" placement="right" effect="light" v-if="item.name && item.name.length > 8" :key="newKey">
                                        <label v-if="!editLineName || recordId !== item.id">{{item.sliName}}</label>
                                        <el-input v-else v-model="item.name" @blur="handleEditBlur" @change="handleEditChange(item.name,item.id,index)"></el-input>
                                    </el-tooltip>
                                    <el-input v-else-if="editLineName && recordId === item.id" v-model="item.name" @blur="handleEditBlur" @change="handleEditChange(item.name,item.id,index)"></el-input>
                                    <label v-else>{{item.name}}</label>
                                </el-form-item>
                                <el-form-item label="kml航线文件" class="fileItem">
                                    <el-tooltip v-if="item.oldFileName && item.oldFileName.length > 9" :content="item.oldFileName" placement="right" effect="light">
                                        <label>{{item.oldFileName.length > 9 ? item.oldFileName.slice(0,3) + '...' + item.oldFileName.slice(item.oldFileName.length - 6,item.oldFileName.length) : item.oldFileName}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.oldFileName ? item.oldFileName : '暂无文件'}}</label>
                                    <!-- <i class="el-icon-circle-close" @click="handleDeleteFile(item)"></i> -->
                                </el-form-item>
                                <el-form-item label="kmz航线文件" class="fileItem">
                                    <el-tooltip v-if="item.kmzOldFileName && item.kmzOldFileName.length > 9" :content="item.kmzOldFileName" placement="right" effect="light">
                                        <label>{{item.kmzOldFileName.length > 9 ? item.kmzOldFileName.slice(0,3) + '...' + item.kmzOldFileName.slice(item.kmzOldFileName.length - 6,item.kmzOldFileName.length) : item.kmzOldFileName}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.kmzOldFileName ? item.kmzOldFileName : '暂无文件'}}</label>
                                    <!-- <i class="el-icon-circle-close" @click="handleDeleteFile(item)"></i> -->
                                </el-form-item>
                                <el-form-item label="道通航线文件" class="fileItem">
                                    <el-tooltip v-if="item.txtOldFileName && item.txtOldFileName.length > 9" :content="item.txtOldFileName" placement="right" effect="light">
                                        <label>{{item.txtOldFileName.length > 9 ? item.txtOldFileName.slice(0,3) + '...' + item.txtOldFileName.slice(item.txtOldFileName.length - 6,item.txtOldFileName.length) : item.txtOldFileName}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.txtOldFileName ? item.txtOldFileName : '暂无文件'}}</label>
                                    <!-- <i class="el-icon-circle-close" @click="handleDeleteFile(item)"></i> -->
                                </el-form-item>
                                <el-form-item class="iconsItem">
                                    <div class="icons">
                                        <el-upload
                                            v-if="!item.oldFileName && !item.kmzOldFileName && !item.txtOldFileName "
                                            class="upload"
                                            ref="upload"
                                            action="#"
                                            :limit="1"
                                            :http-request="uploadFile"
                                            :on-success="handleSuccess"
                                            :show-file-list="false"
                                            accept=".kmz,.kml,.txt"
                                            title="上传"
                                            :file-list="fileList">
                                            <img style="margin-top:5px" src="@images/common/upload.svg">
                                        </el-upload>
                                        <el-upload
                                                ref="upload"
                                                v-if="item.oldFileName || item.kmzOldFileName || item.txtOldFileName"
                                                class="reupload"
                                                action="#"
                                                :http-request="uploadFile"
                                                :on-success="handleSuccess"
                                                :show-file-list="false"
                                                accept=".kmz,.kml,.txt"
                                                title="重新上传"
                                                :limit="1"
                                                :file-list="fileList">
                                            <img style="margin-top:5px" src="@images/common/upload.svg">
                                        </el-upload>
                                        <div v-if="item.oldFileName || item.kmzOldFileName || item.txtOldFileName" class="download" title="下载" style="margin-right:10px">
                                            <img src="@images/common/download.svg"  @click="downloadFile(item)">
                                        </div>
                                        <div class="edit" @click="handleEdit" title="编辑名称">
                                            <img src="@images/airLine/edit.png">
                                        </div>
                                        <div class="delete" @click="handleDelete(item.id)" title="删除航线">
                                            <img src="@images/airLine/delete.png">
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="noData" v-if="firstNull">
                            <label>暂无数据</label>
                        </div>
                    </div>
                    <div class="pagination" v-if="!firstNull">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10,20,50,100]"
                            :page-size="pageSize"
                            :pager-count="5"
                            layout="prev, pager, next"
                            :total="totalPage">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="19">
                <slot></slot>
            </el-col>
        </el-row>
        <el-dialog
            title="新增航线"
            v-drag
            center
            :visible.sync="newAddVisible"
            width="25%">
            <div>
                <label style="margin-right:10px">航线名称</label>
                <el-input v-model="newLineName" placeholder="请输入" clearable></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goAddLine">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
export default {
    name:'dataOutDiv',
    data(){
        return{
            newLineName:'',
            pageSize:4,
            searchName:'',
            totalPage:0,
            currentPage:1,
            editLineName:false,
            newAddVisible:false,
            fileList:[],
            currentId:0,
            taskArrs:[],
            recordFlag:2,
            recordId:0,
            newKey:0,
            firstNull:0,
        }
    },
    mounted(){
        this.searchPatrolInfo()
    },
    methods:{
        // handleDeleteFile(item){

        // },
        goSearch(){
            this.currentPage = 1
            this.searchPatrolInfo()
        },
        downloadFile(data){
            if (data.filePath) {
                // url 文件地址
                fetch(data.filePath).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = data.oldFileName;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(() => {
                    this.$message.error('下载kml文件失败');
                });
            }
            if (data.kmzFilePath) {
                fetch(data.kmzFilePath).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = data.kmzOldFileName;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(() => {
                    this.$message.error('下载kmz文件失败');
                });
            }

            if (data.txtFilePath) {
                fetch(data.txtFilePath).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = data.txtOldFileName;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(() => {
                this.$message.error('下载道通文件失败');
            });
            }
        },
        searchChange(){
            if(!this.searchName){
                this.currentPage = 1
                this.searchPatrolInfo()
            }
        },
        goAddLine(){
            if(!this.newLineName){
                this.$message.warning('请先输入航线名称')
                return
            }
            let params = {
                name: this.newLineName
            }
            api_uav.addLineInfo(params).then(res => {
                if(res.data.code === 200){
                    this.newAddVisible = false
                    this.$message.success(res.data.msg)
                    this.newLineName = ""
                    this.searchPatrolInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        addNewLine(){
            this.newAddVisible = true
        },
        searchPatrolInfo(flag){
            let params = {
                currentPage:this.currentPage,
                pageSize:this.pageSize,
                searchValue:this.searchName
            }
            this.firstNull = true
            api_uav.queryLineInfo(params).then(res => {
                if(res.data.code === 200){
                    this.fileList = []
                    if(res.data.rows.length < 1){
                        
                    }else{
                        this.firstNull = false
                    }
                    this.taskArrs = res.data.rows
                    this.taskArrs.forEach((item,index) => {
                        if(item.name.length > 8){
                            this.taskArrs[index].sliName = item.name.slice(0,8) + '...'
                        }else{
                            this.taskArrs[index].sliName = item.name
                        }
                    })
                    // this.taskArrs.forEach(item => {
                    //     if(item.oldFileName){
                    //         this.fileList.push(item.oldFileName)
                    //     }
                    //     if(item.kmzOldFileName){
                    //         this.fileList.push(item.kmzOldFileName)
                    //     }
                    //     if(item.txtOldFileName){
                    //         this.fileList.push(item.txtOldFileName)
                    //     }
                    // })
                    // console.log(this.taskArrs)
                    this.recordFlag--
                    if(this.recordFlag > 0){
                        this.currentId = this.taskArrs[0].id
                        if(flag !== 2){
                            this.$emit('sendLineId',this.taskArrs[0].id)
                        }
                    }
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleEditBlur(){
            let curItem = this.taskArrs.filter(item => item.id === this.currentId)[0]
            curItem.sliName = curItem.name.slice(0,8) + '...'
            this.editLineName = false
        }, 
        handleEditChange(name,id,index){
            this.editLineName = false
            let params = {
                name:name,
                id:id,
            }
            this.axios.put("/api/patrol/line",params,{
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
                if(res.data.code === 200){
                    this.newKey++
                    this.$message.success(res.data.msg)
                    this.$emit('sendLineId',id)
                    this.searchPatrolInfo(2)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleEdit(){
            
            this.recordId = this.currentId
            // let curItem = this.taskArrs.filter(item => item.id === this.currentId)[0]
            // curItem.sliName = curItem.name
            this.editLineName = true
        },
        handleDelete(id){
            const h = this.$createElement
            this.$confirm('提示', {
                title: '提示',
                message: h('div', [h('p', '删除航线信息同时会删除对应的航线设备'), h('p', '您确定要删除吗？')]),
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                    api_uav.deleteLineInfo(id).then(res => {
                        if(res.data.code === 200){
                            this.$message.success(res.data.msg)
                            this.recordFlag = 2
                            this.currentPage = 1
                            this.searchPatrolInfo()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });          
            });
                return false;
        },
        clickTaskItem(index,id){
            if(this.recordId !== id){
                this.editLineName = false
                this.recordId = 0
                this.newKey --
            }
            this.currentId = id
            this.$emit('sendLineId',id)
        },
        handleSuccess(res, file) {
        },
        uploadFile(param){
            let formdata = new FormData()
            formdata.append('id',this.currentId)
            formdata.append('file',param.file)
            api_uav.uploadLineFile(formdata).then(res => {
                if(res.data.code === 200){
                    const mainImg = this.$refs.upload;
                    if (mainImg && mainImg.length) {
                        mainImg.forEach(item => {
                            item.clearFiles();
                        });
                    }
                    this.$message.success(res.data.msg)
                    this.searchPatrolInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.searchPatrolInfo(2)
        }

    }
}
</script>
<style lang="stylus" scoped>
.noData
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size:15px
    color:grey
@media screen and (max-width: 1630px)
    >>>.el-col-19
        width: calc(100% - 280px) !important
>>>.el-form-item__content
>>>.el-form-item__label
    line-height: 5vh !important
>>>el-message-box__wrapper
    .el-message-box
        .el-message-box__btns
            .el-button--primary
                background-color: #259C9C !important;
                color: white
.editInput
    >>>.el-input__inner
        border 1px solid rgba(73,92,106,0.2)
.readInput
    >>>.el-input__inner
        border 1px solid rgba(255,255,255,0.5) !important
.outDiv
    width: 100%
    height: 100%
    overflow-y: hidden
    >>>.el-dialog
        min-width: none !important
        .el-dialog__header
            text-align: left
        .el-dialog__body
            height: 10vh
            display: flex
            justify-content: center
            align-items: center
            .el-input
                width: 70%
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
    >>>.el-row
        height: 100%
    >>>.el-col-5
        min-width: 280px
        border-right: 1px solid rgba(73,92,106,0.2);
    .top
        height: 10%
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        font-size: 14px
        border-bottom: 1px solid rgba(73,92,106,0.2);
        >>>.el-input
        >>>.el-input__inner
            height: 36px
            border-radius: 8px
        >>>.el-input
            margin-right: 10px
        .inner
            width: 90%
            display: inline-flex
            justify-content: center
            align-items: center
            :hover
                cursor pointer
            .top_icon
                width: 26px
                height: 24px
                background: #1F9999
                display: flex
                justify-content: center
                align-items: center
                color: white
                border-radius: 50px
    .bottom
        height: 90%
        display: flex
        justify-content: center
        position: relative
        .pagination
            display: block
            position: absolute
            bottom: 10px
            right: 5px
        .inner
            height: 100%
            width: 90%
        .taskActive
            border: 1px solid #199999 !important
            // background: rgba(250,250,250,0.5)
            >>>.el-input__inner
                background: transparent
        .taskItem
            position: relative
            height: 20%
            width: 100%
            border: 1px solid rgba(73,92,106,0.2);
            display: inline-flex
            justify-content: center
            margin-top: 5%
            &:hover
                border: 1px solid #199999
            >>>.el-form
                height: 100%
                width: 100%
            >>>.el-form-item
                margin: 0 !important
                height: 18%;
                display: flex;
            >>>.el-form-item__content
                margin-left: 0% !important
            >>>.el-form-item__label
                padding: 0 0 0 5%
                font-size: 14px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #495C6A;
            >>>.el-input__inner
                padding-left: 10px
                margin-left: 10px
                height: 25px
                width: 90%
                &:focus
                    background: white
            .fileItem
                >>>.el-icon-circle-close
                    margin-left: 20px
                // &:hover
                //     label
                //         background #E9F5F5
                //     >>>.el-icon-circle-close
                //         color #259C9C
            .iconsItem
                height: 28% !important
                >>>.el-form-item__content
                    width: 100%
                    position: relative
            .icons
                position: absolute
                right: 10px
                top: 5px
                height: 25px !important
                div
                    display: inline-flex
                    justify-content: center
                    align-items: center
                    width: 3vh;
                    height: 3vh;
                    background: #FFFFFF;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    border: 1px solid #E2EAEC;
                    :hover
                        cursor pointer
                    img
                        width: 62%;
                        height: 62%;
                .edit
                .upload
                    margin-right: 10px
                .upload, .reupload
                    position: relative
                    top: -4px
                    margin-right: 10px
                    >>>.el-upload--text
                        position: relative
                        top: 5px

</style>