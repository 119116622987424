import Vuex from 'vuex'
import Vue from 'vue'
import router from '../router/index'
Vue.use(Vuex)
let store = new Vuex.Store({
    state:{
        tab:'/task_manage',
        lineTaskid:'',
        patrolTaskid:'',
        id:'',
        recordTab:[],
        dispatchId:'',
        isLogin: false,
        username: '' 
    },
    getters:{

    },
    mutations:{
        userLogin(state, payload) {
            state.isLogin = payload.isLogin
            state.username = payload.username
        },
        clickLabel(state, item){
            if(item.tab){
                state.tab = item.tab
                if(state.recordTab.indexOf(item.tab) < 0){
                    state.recordTab.push( item.tab )
                    router.push({name:item.tab})
                }
            }
            if(item.patrolTaskid){
                state.patrolTaskid = item.patrolTaskid
            }
            if(item.dispatchId){
                state.dispatchId = item.dispatchId
            }
            if(item.lineTaskid){
                state.lineTaskid = item.lineTaskid
            }
            if(item.id){
                state.id = item.id
            }
        },
        clickTab(state, tab){
            state.tab = tab
            router.push({name:tab})
        },
        deleteTab(state, tab){
            state.recordTab.splice(state.recordTab.indexOf(tab),1)
        },
    },
    actions:{

    },
    modules:{

    }
})

export default store