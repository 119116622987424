<template>
  <div class="map">
    <div id="mapContent" 
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"></div>
    <layer-div 
      :class="layerControlShow?'openControl':'closeControl'"
      :showOrHide="layerControlShow"
      @changeLayer="changeLayer"
      @showLayer="showLayer"
      ref="layerdiv"
    >
    </layer-div>
    <div class="leftListDiv">
      <left-list :class="leafListShow?'showList':'hiddenList'" @clearAll="clearDraws" @sendTaskId="sendTaskId"></left-list>
        <i class="el-icon-caret-right" @click="showLeftList" v-if="!leafListShow"></i>
        <div class="back_icon" v-else @click="showLeftList">
            <!-- <img src="@images/maps/controls/back.svg"> -->
            <i class="el-icon-d-arrow-left"></i>
        </div>
    </div>
  </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import 'leaflet-polylinedecorator'
import 'leaflet.animatedmarker/src/AnimatedMarker'
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import LayerDiv from '@components/maps/LayerDiv'
import LeftList from '@components/maps/LeftList'
import api_uav from '@/utils/api/api_uav';
export default {
  name:'Map',
  components:{
    LayerDiv,
    LeftList
  },
  data(){
    return{
      mapObj:{ //leaflet地图对象
        map:null, //地图
        baseLineLayer:null, //边界线图层
        drawPointLayer:null, //点图层
        drawLineLayer:null,  //线图层
        drawPopLayer:null,   //pop图层
        drawTextLayer: null,  //文字标注
        
      },
      loading:false,
      imgList:[
        '/maps/controls/dixing.jpg',
        '/maps/controls/shiliang.jpg',
        '/maps/controls/yingxiang.jpg',
      ],
      picPopShow:false,
      leafListShow:true,
      layerOptions:null, //底图选项
      markerArrs : [ //点位数据
        // {
        //   lat: 33.92, //纬度
        //   lng: 108.8, //经度
        //   title: '1'
        // },
        // {
        //   lat: 33.95,
        //   lng: 108.5,
        //   title: '2'
        // },
        // {
        //   lat: 33.98,
        //   lng: 108.6,
        //   title: '3'
        // },
        // {
        //   lat: 34.1,
        //   lng: 108.7,
        //   title: '4'
        // }
      ],
      mapLayer:{}, 
      layerControlShow:false, //底图控件的显隐
      viewer:null,
      uavIconSrc:'uavIcon3.svg',
      idsObj:{},
    }
  },
  mounted(){
    this.initMap()
    window.viewPic = () => {
      this.viewPic()
    }
  },
  methods:{
    clearDraws(){
      this.mapObj.drawPointLayer.clearLayers()
      this.mapObj.drawLineLayer.clearLayers()
      this.mapObj.drawPopLayer.clearLayers()
      this.mapObj.drawTextLayer.clearLayers()
      this.mapObj.map.setView(MAP_OPTIONS.center, 10);
    },
    sendTaskId(data){
      this.clearDraws()
      this.idsObj = data
      let params = {
        ids: this.idsObj.id
      }
      api_uav.getEquipByAirLineId(params).then(res => {
        if(res.data.code === 200){
          this.markerArrs = []
          res.data.data.forEach(x => {
            let obj = {}
            if(x.actualLatitude && x.actualLongitude){
              obj = {
                lat: x.actualLatitude,
                lng: x.actualLongitude,
                title: x.equipName,
                id: x.id,
                airLineId: x.airLineId,
                equipId:x.equipId,
              }
              this.markerArrs.push(obj)
            }
          })
          if(this.markerArrs.length > 0){
            this.mapObj.map.setView([this.markerArrs[0].lat,this.markerArrs[0].lng], MAP_OPTIONS.flyZoom);
          }
          this.drawLine()
          this.drawPoint()
          // this.drawText()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    //初始化地图
    initMap(){
      this.layerOptions = MAP_OPTIONS.layers.map(item => {
        return [L.tileLayer(this.getTdt(item.type)),L.tileLayer(this.getTdt(item.text))]
      });
      var baseLayers = {}
      MAP_OPTIONS.layers.forEach((item,index) => {
        baseLayers[item.label] = L.layerGroup(this.layerOptions[index])
      })
      this.mapObj.map = L.map('mapContent',{  //需绑定地图容器div的id
        center:MAP_OPTIONS.center, //初始地图中心
        zoom:MAP_OPTIONS.zoom, //初始缩放等级
        maxZoom:MAP_OPTIONS.maxZoom, //最大缩放等级
        minZoom:MAP_OPTIONS.minZoom, //最小缩放等级
        zoomControl:false,//不显示缩放小控件
        layers:[baseLayers['矢量底图']],
        // preferCanvas: true
      })
      L.control.zoom({position:'bottomright'}).addTo(this.mapObj.map);
      L.control.layers(baseLayers,null).addTo(this.mapObj.map);
      
      //切换图层
      for(let node of document.querySelectorAll('.leaflet-control-layers-base label')){
          this.mapLayer[node.innerText.trim()] = node.querySelector('input')
      }
      //创建边界线绘制图层
      this.mapObj.baseLineLayer = new L.FeatureGroup()
      this.mapObj.map.addLayer(this.mapObj.baseLineLayer)
      //创建点位绘制图层
      this.mapObj.drawPointLayer = new L.FeatureGroup()
      this.mapObj.map.addLayer(this.mapObj.drawPointLayer)
      //创建折现绘制图层
      this.mapObj.drawLineLayer = new L.FeatureGroup()
      this.mapObj.map.addLayer(this.mapObj.drawLineLayer)
      //创建弹框绘制图层
      this.mapObj.drawPopLayer = new L.FeatureGroup()
      this.mapObj.map.addLayer(this.mapObj.drawPopLayer)
      //创建标题绘制图层
      this.mapObj.drawTextLayer = new L.FeatureGroup()
      this.mapObj.map.addLayer(this.mapObj.drawTextLayer)
      // this.drawLine()
      // this.drawPoint()
      this.drawBaseLine()

      // this.mapObj.map.on('zoomend', (e) => {
      //     //获取当前放大或者缩小的等级
      //     let scale = e.target.getZoom();
      //     if (this.markerArrs.length > 0) {
      //       if (scale > 16) {
      //         this.drawText()
      //       } else {
      //         this.mapObj.drawTextLayer.clearLayers()
      //       }
      //     }
      // });
    },
    drawBaseLine(){
      let arr = []
      this.axios.get('/data/xian.json').then(res => {
        arr = res.data.features[0].geometry.coordinates[0]
        arr[0] = arr[0].map( x => {
          let temp = x
          temp = JSON.parse(JSON.stringify(temp))
          return temp.reverse()
        })
        
        let opitons = {
          color:'#228F8D',
          fillColor:'#228F8D',
          opacity:'0.5',
          weight:'6'
        }
        // let pathPattern = L.polylineDecorator(arr, {
        //     //添加模式
        //     patterns: [{
        //         //模式符号的偏移位置
        //         offset: 12,
        //         //模式符号的重复间隔
        //         repeat: 25,
        //         //符号实例
        //         symbol: L.Symbol.dash({
        //             //符号大小
        //             pixelSize: 10,
        //             //符号样式
        //             pathOptions: {
        //                 //颜色
        //                 color: '#f00',
        //                 //线宽
        //                 weight: 2
        //             }
        //         })
        //     },
        //         {
        //             //模式符号的偏移位置
        //             offset: 0,
        //             //模式符号的重复间隔
        //             repeat: 25,
        //             //符号实例
        //             symbol: L.Symbol.dash({
        //                 //符号大小
        //                 pixelSize: 0
        //             })
        //         }
        //     ]
        // }).addTo(this.mapObj.baseLineLayer);
        let polyline = L.polyline(arr,opitons).addTo(this.mapObj.baseLineLayer)
      })
    },
    drawText(){
      let _this = this
      _this.markerArrs.forEach(x => {
        var myIcon = L.divIcon({
          html: `<div>${x.title}</div>`,
          className: 'my-div-icon',
        });
        let marker = L.marker([+x.lat - 0.0001,+x.lng - 0.0003],{
          icon:myIcon,
          title:x.title
        })
        marker.addTo(this.mapObj.drawTextLayer)
      })
    },
    //点位 
    drawPoint(){
      let _this = this
      let icon = L.icon({
        iconUrl:require(`@images/maps/markers/${this.uavIconSrc}`),
        iconSize:[30,30]
      })
      _this.markerArrs.forEach(x => {
        //自定义图标
        
        let marker = L.marker([x.lat,x.lng],{
          icon:icon,
          title:x.title
        })

        //圆圈图标
        // let options = {
        //   color:'red',
        //   fillColor:'red',
        //   fillOpacity:1,
        //   opacity:1,
        //   radius:8,
        //   title:x.title,
        // }
        // let marker = L.circleMarker([x.lat,x.lng],options)

        marker.on('click',(e) => {
          this.loading = false
          let params = {
            equipId:x.equipId,
            airLineId:x.airLineId,
            patroltaskId:this.idsObj.taskid
          }
          api_uav.getPictureInfo(params).then(res => {
            this.loading = false
            if(res.data.code === 200){
              if(res.data.rows[0]){
                let dataArr = res.data.rows
                let stringHtml = ''
                dataArr.forEach((x,i) => {
                  if(x.filePath){
                    if(i === 0){
                      stringHtml += `<img data-original="${x.filePath}" class="picshow" src="${x.filePath}">`
                    }else{
                      stringHtml += `<img data-original="${x.filePath}" class="pichidden" src="${x.filePath}">`
                    }
                  }
                })
              let popup = L.popup().setLatLng([e.latlng.lat, e.latlng.lng])
              .setContent(`
                      <div id="viewer" onclick="viewPic()">
                        <div class="viewInner">
                          ${stringHtml}
                        </div>
                      </div>
              `).openOn(_this.mapObj.drawPopLayer) //定义泡泡弹框
              }else{
                this.$message.warning('暂无可预览照片')
              }
            }else{
              this.$message.error(res.data.msg)
            }
          })
        })
        
        marker.addTo(_this.mapObj.drawPointLayer)
      })
    },
    viewPic() {
      var galley = document.getElementById('viewer');
      this.viewer = new Viewer(galley, {
        url: 'data-original',
        hidden: function() {
          this.viewer.destroy();
        }
      });
      this.viewer.show();
    },
    //线段
    drawLine(){
      let latlng = this.markerArrs.map(x => {
        return [ x.lat, x.lng ]
      })
      let opitons = {
        // color:'#ED5655',
        // fillColor:'#ED5655',
        opacity:'0.8',
        weight:'8'
      }
      let lines = L.polyline(latlng,opitons)
      lines.addTo(this.mapObj.drawLineLayer)

      //箭头线段
      var decorator = L.polylineDecorator(lines, {
          patterns: [{
              repeat: 25,
              symbol: L.Symbol.arrowHead({
                  pixelSize: 8,
                  headAngle: 65,
                  polygon: false,
                  pathOptions: {
                      stroke: true,
                      weight: 3.5,
                      color: '#FFFFFF'
                  }
              })
          }]
      }).addTo(this.mapObj.drawLineLayer);
    },
    //图层控制的显示和隐藏
    showLayer(value){
      this.layerControlShow = value
    },
    showLeftList(){
      this.leafListShow = !this.leafListShow
    },
    //点击切换底图
    changeLayer(value){
      this.mapLayer[value].click()
      // if (value === '影像地图') {
      //   var x = document.getElementsByClassName("my-div-icon");
      //   var i;
      //   for (i = 0; i < x.length; i++) {
      //       x[i].style.color = "white";
      //   }
      // } else {
      //   var x = document.getElementsByClassName("my-div-icon");
      //   var i;
      //   for (i = 0; i < x.length; i++) {
      //       x[i].style.color = "black";
      //   }
      // }
    },
    //天地图地址
    getTdt(params){  
      let result = `http://t0.tianditu.gov.cn/${params}/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=${params.split('_')[0]}&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${TDT_KEY}`
      return result
    },
    
  }
}
</script>
<style>
  .leaflet-control-layers{
    visibility: hidden;
  }
  .leaflet-popup-content-wrapper{
    border-radius: 0;
    width: 300px;
    height: 200px;
  }
  .leaflet-popup-content-wrapper #viewer :hover{
    cursor: pointer;
  }
  .picshow{
    width:250px;
    height:170px
  }
  .pichidden{
    width:250px;
    height:170px;
    display: none;
  }
</style>
<style lang="stylus" scoped>
>>>.my-div-icon
  width: 200px !important
  height: 30px !important
.map
  .openControl
    width : 350px !important
  .closeControl
    height: 80px !important
    width: 80px !important
  #mapContent
    height: 100%
    width: 100%
    position: absolute
    right: 0px
    border-radius: 8px 0px 0px 8px;
  .layer_div
    position: absolute
    right: 0px
    z-index 1000
    width: 80px
    height: 100px
  .picture_pop
    position: absolute
    top 200px
    left: 200px
    z-index 1000
    width: 400px
    height: 300px
    border: 2px solid black
  .outDiv
    position: absolute
    z-index: 1000
    background: rgba(68,72,57,0.7)
    color:white
    width: 270px
    height: 100%
    // display: none
    margin-left: -250px
  >>>.el-icon-caret-right
    color: rgba(68,72,57,0.7)
    position: absolute
    margin-left:-20px
    top:40vh
    font-size: 50px
    z-index: 1000
  .showList
    display: block
    // transform: translateX(0px);
    // position absolute
    // z-index: 2000
    // transition: transform 1s
    margin-left: 0px !important
  .hiddenList
    display: none
    // position absolute
    // z-index: -100
    // transform: translateX(-400px);
  .back_icon
    width: 32px
    height: 32px
    border-radius: 50px
    background: #797C6F
    // border: 1px solid white
    box-shadow: -2px 2px 8px black
    position: absolute
    left 252px
    top:2.5vh
    z-index 1000
    display: flex
    justify-content: center
    align-items: center
    &:hover
      cursor pointer
    i
      color white
      font-size:20px
    img
      width: 100%
      height: 100%
</style>