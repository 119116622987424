<template>
    <div 
        class="pictureManager" 
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <out-div @sendTaskId='sendTaskId'>
            <div class="rightContent">
                <div style="height:100%">
                    <div class="top_first">
                        <div>
                            <label>设备名称</label>
                            <el-input v-model="deviceName" placeholder="请输入名称" clearable @change="searchChange" @keydown.enter.native="goSearch"></el-input>
                        </div>
                        <div>
                            <label>设备类型</label>
                            <el-select v-model="isFlaw">
                                <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="Btn">
                            <el-button class="search" @click="goSearch">查询</el-button>
                            <el-button class="reset" @click="resetSearch">重置</el-button>
                            <el-button class="download" @click="downloadPicture">下载照片</el-button>
                            
                        <!-- <div class="picture"> -->
                            <!-- <el-button class="picture">同步照片</el-button> -->
                        <!-- </div> -->
                        </div>
                    </div>
                    <div class="top_second">
                        <!-- <div>
                            <label>是否缺陷</label>
                            <el-select v-model="isFlaw">
                                <el-option label="是" value="true"></el-option>
                                <el-option label="否" value="false"></el-option>
                            </el-select>
                        </div> -->
                        
                        <!-- <div> -->
                            <!-- <label>拍摄日期</label> -->
                            <!-- <el-date-picker
                                v-model="picDate"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker> -->
                            <!-- <el-date-picker
                                v-model="picDate"
                                type="daterange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker> -->
                        <!-- </div> -->
                    </div>
                    <div class="bottom">
                        <div class="inner">
                            <el-table
                                resizable
                                highlight-current-row
                                :data="tableData"
                                style="width:100%;"
                                :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                                max-height="calc(100vh - 250px)"
                                tooltip-effect="light"
                                :row-style="{height: '40px'}"
                                :cell-style="{padding: '0.64%'}"
                                row-key="id"
                                @selection-change="handleSelectionChange"
                                >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                    align="center"
                                    show-overflow-tooltip
                                    :label="item.label"
                                    :prop="item.prop"
                                    min-width="100"
                                    sortable>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    label="操作"
                                    width="300">
                                    <template slot-scope="scope">
                                        <el-button @click="handleClick(scope.row)" type="text" size="small" class="greenBtn">预览照片</el-button>
                                        <el-button @click="handleAIAnalysis(scope.row)" type="text" size="small" class="darkgreenBtn">
                                            {{Number(scope.row.aiStatus) === 0 ? 'AI分析' : '查看分析结果'}}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10,20,50,100]"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, sizes, jumper"
                            :total="totalPage">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </out-div>
        <viewer :options="options" :images="images"
            @inited="inited" class="viewer" ref="viewer" v-show="previewShow">
            <template slot-scope="scope">
                <img v-for="src in scope.images" :src="src" :key="src">
                {{scope.options}}
            </template>
        </viewer>
        <viewer :options="options" :images="AIImages"
            @inited="AIInited" class="viewer" ref="AIviewer" v-show="AIPreviewShow">
            <template slot-scope="scope">
                <img v-for="src in AIImages" :src="src" :key="src.id">
                {{scope.options}}
            </template>
        </viewer>
        <!-- <el-dialog
            title="照片"
            center
            :visible.sync="previewShow"
            width="40%">
            <div @click="showPreview" id="viewer">
                <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" data-original="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg">
            </div>
        </el-dialog> -->
    </div>
</template>
<script>
import OutDiv from '@components/patrolResult/OutDiv'
import api_uav from '@/utils/api/api_uav'
import 'viewerjs/dist/viewer.css';
import axios from 'axios'
// import Viewer from "v-viewer/src/component.vue"
import Viewer from 'viewerjs';
export default {
    name:'PictureManager',
    components:{
        OutDiv
    },
    data(){
        return{
            notifyList: null,
            loading:false,
            options:{
                title:true,
                toolbar:true,
                navbar:true,
            },
            viewer:null,
            AIViewer: null,
            previewShow:false,
            deviceName:'',
            picDate:'',
            isFlaw:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            AIImages: [],
            AIPreviewShow: false,
            images:['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'],
            deviceTypeArr:[
                {
                    label:'定点',
                    value:0,
                },
                {
                    label:'箱式变压器',
                    value:1,
                },
                {
                    label:'柱上变压器',
                    value:2,
                },
                {
                    label:'开闭所',
                    value:3,
                },
                {
                    label:'环网柜',
                    value:4,
                },
                {
                    label:'分支箱',
                    value:5,
                },
                {
                    label:'配电室',
                    value:6,
                },
                {
                    label:'杆塔',
                    value:7,
                },
                {
                    label:'开关',
                    value:8,
                },
                {
                    label:'其他',
                    value:9,
                },
            ],
            tableColumns:[
                {
                    label:'序号',
                    prop:'sort',
                },
                {
                    label:'设备名称',
                    prop:'equipName',
                },
                {
                    label:'设备类型',
                    prop:'equipType',
                },
                // {
                //     label:'照片名称',
                //     prop:'pictureName',
                // },
                // {
                //     label:'拍摄时间',
                //     prop:'pictureDate',
                // }
            ],
            tableData: [],
            curTaskId: null,
            multipleSelection: []
        }
    },
    methods:{
        goSearch(){
            this.currentPage = 1
            this.queryDeviceInfo()
        },
        searchChange(){
            if(!this.deviceName){
                this.currentPage = 1
                this.queryDeviceInfo()
            }
        },
        resetSearch(){
            this.currentPage = 1
            this.deviceName = ''
            this.isFlaw = ''
            this.picDate = ''
            this.queryDeviceInfo()
        },
        inited (viewer) {
            this.$viewer = viewer
        },
        AIInited(viewer) {
            this.$AIViewer = viewer
        },
        showPreview(){
            this.previewShow = false
            var galley = document.getElementById('viewer');
            this.viewer = new Viewer(galley, {
                url: 'data-original',
                hidden: function() {
                this.viewer.destroy();
                }
            });
            this.viewer.show();
        },
        handleAIAnalysis(item){
            if(Number(item.aiStatus) === 0) {
                this.notifyList = this.$notify.info({
                    title: '消息',
                    message: `${item.equipName} 正在进行AI分析...`,
                    duration: 0,
                });
                let obj = {
                    airLineId: item.airLineId,
                    patroltaskId: this.curTaskId.taskid,
                    equipId: item.equipId,
                    equipName: item.equipName
                }
                api_uav.handleStartAI(obj).then(res => {
                    // setTimeout(() => {
                        this.notifyList.close()
                        if(res.data.code === 200) {
                            this.$notify.success({
                                title: '消息',
                                message: `${item.equipName}  AI分析已完成`
                            });
                            this.queryDeviceInfo()
                        } else {
                            this.$notify.error({
                                title: '消息',
                                message: `${item.equipName} AI分析失败`
                            });
                        }
                    // }, 2000);
                })
            } else {
                let obj = {
                    id: item.id,
                    airLineId: item.airLineId,
                    patroltaskId: this.curTaskId.taskid,
                    equipId: item.equipId,
                    equipName: item.equipName
                }
                api_uav.getAIResult(obj).then(res => {
                    // console.log(res.data)
                    this.$AIViewer.show()
                    this.AIImages = Object.values(res.data.aiPath[0])

                })
            }
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        downloadPicture(){
            if (!this.multipleSelection || this.multipleSelection.length < 1) {
                this.$message.warning('请先勾选要下载照片的设备')
                return
            } else {
                let params = this.multipleSelection.map(x => {
                    return {
                        patroltaskId: this.curTaskId.taskid,
                        airLineId: x.airLineId,
                        equipId: x.equipId,
                        equipName: x.equipName
                    }
                })
                
                axios.post('/api/patrol/picture/downPictureByTaskIdAndAirLineId', params, {
                    responseType: 'blob', //指定返回数据的格式为blob
                    headers: {
                        'Authorization': sessionStorage.getItem('token')
                    }
                }).then(res => {
                    if (res.data.size > 0) {
                        const blob = new Blob([res.data],{type:"application/zip"})
                        if (blob) {
                            // 这里就是创建一个a标签，等下用来模拟点击事件
                            const a = document.createElement('a')
                            // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
                            const URL = window.URL || window.webkitURL
                            // 根据解析后的blob对象创建URL 对象
                            const herf = URL.createObjectURL(blob)
                            // 下载链接
                            a.href = herf
                            // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
                            a.download = '巡检照片.zip'
                            document.body.appendChild(a)
                            // 点击a标签，进行下载 
                            a.click()
                            // 收尾工作，在内存中移除URL 对象
                            document.body.removeChild(a)
                            window.URL.revokeObjectURL(herf)
                        }   else {
                            this.$message.warning('暂无可下载的照片')
                        }
                    } else {
                        this.$message.warning('暂无可下载的照片')
                    }
                }).catch(error => {
                    this.$message.error(error)
                })
                // let data = {
                //     filePath: "http://118.195.165.226:8080/profile/patrolPicture/2022/12/01/DJI_20221201165006_0001_T_20221201165441A550.JPG",
                //     oldFileName: '照片'
                // }
                // fetch(data.filePath).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                //     const link = document.createElement('a');
                //     link.href = URL.createObjectURL(blob);
                //     link.download = data.oldFileName;
                //     link.target = '_blank';
                //     document.body.appendChild(link);
                //     link.click();
                //     link.remove();
                // }).catch(() => {
                //     this.$message.error('照片下载失败');
                // });
            }
        },
        handleClick(item){
            this.loading = true
            let params = {
                airLineId: this.curTaskId.id,
                patroltaskId: this.curTaskId.taskid,
                equipId:item.equipId
            }
            api_uav.getPictureInfo(params).then(res => {
                this.loading = false
                if(res.data.code === 200){
                    let temp = res.data.rows
                    if(temp[0]){
                        this.images = temp.map(x => x.filePath)
                        this.$viewer.show()
                    }else{
                        this.$message.warning('无可预览照片')
                    }
                    
                }else{
                    this.$message.error(res.data.msg)
                }
            })
            
        },
        sendTaskId(data){
            if(data){
                this.currentPage = 1
                this.queryDeviceInfo(data)
            }else{
                this.tableData = []
            }
            
        },
        queryDeviceInfo(data){
            if(data){
                this.curTaskId = JSON.parse(JSON.stringify(data))
            }
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                equipName: this.deviceName,
                equipType: this.isFlaw,
                ids: this.curTaskId.id,
                patrolTaskId: this.curTaskId.taskid
            }
            api_uav.getPictureEquipByAirLineId(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData = this.tableData.map(x => {
                        x.equipType = this.deviceTypeArr[x.equipType].label
                        return x
                    })
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.currentPage = 1
            this.pageSize = val
            this.queryDeviceInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryDeviceInfo()
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'min-width:1340px')
    },
    beforeDestroy(){
        document.querySelector('body').setAttribute('style', 'min-width:none')
    }
}
</script>
<style lang="stylus" scoped>
@media screen and (max-width: 1690px)
    .top_first
    .top_second
        font-size: 13px
        >>>.el-button
            width:50px !important
            height: 30px !important
            font-size: 13px !important
        .picture
            width:60px !important
    .top_first
        div
            label
                margin 0 5% 0 4% !important
.pictureManager
    width: 100%
    height: 100%
    >>>.el-dialog
        .el-dialog__header
            text-align: left
        .el-dialog__body
            margin-top 0vh !important
            margin 30px 0 0 0
            display: flex
            justify-content: center
            align-items: center
            div
            img
                height: 100%
                width: 100%
            div
                &:hover
                    cursor pointer

    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            .picture
                // position: absolute
                // left: 15px
                // bottom: 10px
                width: 80px;
                height: 32px;
                background: rgba(37,156,156,0.1);
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #259C9C;
                display: flex
                justify-content: center
                align-items: center
                color: #259C9C;
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 5%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                    .el-input
                        width: 100%
                        height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:55px
            width:100%
            div
                label
                    margin : 0 2%
                    position: relative
                    bottom 5px
                width:69.5%
                >>>.el-range-input
                    position: relative
                    bottom: 7px
                >>>.el-range-separator
                    position: relative
                    bottom: 7px
                >>>.el-input
                    width: 60%
                    height: 36px
        .top_first
            // padding:  1% 0
            top: 10px
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
                .download
                    color: #259c9c;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259c9c;
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:60px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn, .darkgreenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .darkgreenBtn
                color: #259C9C
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 330px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 330px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>