<template>
    <div class="layer_div">
        <div class="content">
            <div class="tucengDiv" @click="handleShowLayer">
                <!-- <viewer></viewer> -->
                <img src="@images/maps/controls/tuceng.svg" v-if="recordShow === false"> 
                <img src="@images/maps/controls/arrow_right.svg" v-else>
            </div>
            <div v-for="(item) in mapOptions.layers" :key="item.id" @click="changeLayer(item.label,item.id)" class="itemDiv" :class="{'borderShow' : item.id === layid , 'borderHidden' : item.id !== layid,'openLayer':showOrHide,'closeLayer':!showOrHide}">
                <img :src="require('@/assets/images' + item.image)">
                <div class="titleDiv">{{item.label}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'LayerDiv',
    props:{
        showOrHide:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return{
            mapOptions:MAP_OPTIONS,
            recordShow:false,
            layid:0,
        }
    },
    computed:{

    },
    methods:{
        handleShowLayer(){
            this.recordShow = !this.recordShow
            this.$emit('showLayer',this.recordShow)
        },
        changeLayer(label,id){
            this.$emit('changeLayer',label)
            this.layid = id
        }
    },
    mounted(){
    }
}
</script>
<style lang="stylus" scoped>
.layer_div
    .content
        width: 100%
        height: 100%
        display: flex
        justify-content: space-around
        align-items: center
        background: rgba(68,72,57,0.7)
        border-radius: 5px
        .tucengDiv
            display: flex
            align-items: center
            justify-content: center
            width: 15%
            :hover
                cursor pointer
            img
                height: 2rem
                width: 2rem
        .openLayer
            display: block
        .closeLayer
            display: none !important
        .borderShow
            border: 2px solid #209899
        .borderHidden
            border: none
        .itemDiv
            display: inline-block
            color: white
            font-size: 14px
            text-align: center
            img
                width: 70px
                height: 70%
                display: block
            .titleDiv
                background: #209899
</style>