/*
 * @Author: your name
 * @Date: 2020-05-20 21:42:01
 * @LastEditTime: 2020-05-26 10:20:50
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \webapp\src\utils\http.js
 */
// 导入封装好的axios实例
import request from './request'
var token = ''
const http = {
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    get(url, params) {
        token = sessionStorage.getItem("token")
        const config = {
            method: 'get',
            url: encodeURI(url),
            headers: {
                'Authorization': token
            }
        }
        if (params) config.params = params
        return request(config)
    },
    post(url, params) {
        token = sessionStorage.getItem("token")
        const config = {
            method: 'post',
            url: encodeURI(url),
            headers: {
                'Authorization': token
            }
        }
        if (params) config.data = params
        return request(config)
    },
    put(url, params) {
        token = sessionStorage.getItem("token")
        const config = {
            method: 'put',
            url: encodeURI(url),
            headers: {
                'Authorization': token
            }
        }
        if (params) config.params = params
        return request(config)
    },
    delete(url, params) {
        token = sessionStorage.getItem("token")
        const config = {
            method: 'delete',
            url: encodeURI(url),
            headers: {
                'Authorization': token
            }
        }
        if (params) config.params = params
        return request(config)
    }
}
//导出
export default http