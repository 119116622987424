<template>
    <div class="patroltask">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>任务单号</label>
                        <el-input v-model="deviceName" placeholder="请输入名称" clearable @change="searchChange" @keydown.enter.native="goSearch"></el-input>
                    </div>
                    <div class="deviceType">
                        <label>状态</label>
                        <el-select v-model="isFlaw">
                            <el-option v-for="item in taskStatusArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset" @click="resetSearch">重置</el-button>
                        <el-button class="newadd" @click="handleNewAdd">新增</el-button>
                        <!-- <el-button class="newadd" @click="handleNewAdd">新增</el-button> -->
                    </div>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            resizable
                            highlight-current-row
                            :data="tableData"
                            style="width:100%;"
                            max-height="calc(100vh - 220px)"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            :row-style="{height: '40px'}"
                            :cell-style="{padding: '0.505%'}"
                            tooltip-effect="light"
                            >
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                min-width="130"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                align="center"
                                label="操作"
                                width="150">
                                <template slot-scope="scope">
                                    <el-button @click="handleClickLine(scope.row)" type="text" size="small" class="greenBtn">航线</el-button>
                                    <!-- <el-button type="text" size="small" class="greenBtn">编辑</el-button> -->
                                    <el-button @click="handleClickDetail(scope.row)" type="text" size="small" class="greenBtn">巡检设备</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            title="新增巡检任务"
            center
            v-drag
            :visible.sync="newAddShow"
            width="50%">
            <div style="width:80%">
                <el-form ref="form" :model="newAddForm" label-width="150px" :rules="rules">
                    <el-form-item label="任务单号" prop="tasknumber">
                        <el-input v-model="newAddForm.tasknumber" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="任务类型" prop="tasktype">
                        <!-- <el-input v-model="newAddForm.tasktype" placeholder="请输入"></el-input> -->
                        <el-select v-model="newAddForm.tasktype" placeholder="请选择">
                            <el-option value="1" label="巡视"></el-option>
                            <el-option value="2" label="缺陷"></el-option>
                            <el-option value="5" label="隐患"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="任务性质">
                        <el-input v-model="newAddForm.taskproperty" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="newAddForm.taskstatus">
                            <el-option v-for="item in taskStatusArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计划开始时间" prop="planbegintime">
                        <el-date-picker
                            v-model="newAddForm.planbegintime"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划结束时间" prop="planendtime">
                        <el-date-picker
                            v-model="newAddForm.planendtime"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划工作内容" prop="plantaskcontent">
                        <el-input v-model="newAddForm.plantaskcontent" type="textarea" :rows="2" resize placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSubmit('form')">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import '@/utils/dialogdrag'
import api_uav from '@utils/api/api_uav.js'
export default {
    name:'TaskManager',
    data(){
        return{
            deviceName:'',
            isFlaw:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            newAddShow:false,
            newAddForm:{
                tasknumber:'',
                tasktype:'',
                taskproperty:'',
                taskstatus:'',
                planbegintime:'',
                planendtime:'',
                plantaskcontent:'',
            },
            rules: {
                tasknumber: [{ required: true, message: "请输入任务单号", trigger: "blur" }],
                tasktype: [{ required: true, message: "请选择任务类型", trigger: "change" }],
                planbegintime: [{ required: true, message: "请选择开始事件", trigger: "change" }],
                planendtime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
                plantaskcontent: [{ required: true, message: "请输入计划工作内容", trigger: "blur" }],
            },
            tableColumns:[
                {
                    label:'任务单号',
                    prop:'tasknumber',
                },
                {
                    label:'任务类型',
                    prop:'tasktypetext',
                },
                {
                    label:'任务性质',
                    prop:'taskproperty',
                },
                {
                    label:'状态',
                    prop:'taskstatus',
                },
                {
                    label:'计划开始时间',
                    prop:'planbegintime',
                },
                {
                    label:'计划结束时间',
                    prop:'planendtime',
                }
                ,
                {
                    label:'计划工作内容',
                    prop:'plantaskcontent',
                }
            ],
            taskStatusArr:[
                {
                    label:'未派单',
                    value:1,
                },
                {
                    label:'已派单',
                    value:2,
                },
                {
                    label:'已接单',
                    value:3,
                },
                {
                    label:'已回执',
                    value:4,
                },
                {
                    label:'归档',
                    value:5,
                }
            ],
            tableData: []
        }
    },
    mounted(){
        this.queryPatrolTaskInfo()
    },
    methods:{
        goSearch(){
            this.currentPage = 1
            this.queryPatrolTaskInfo()
        },
        handleSubmit(formName){
             this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = this.newAddForm
                api_uav.newAddPatrolTask(params).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.newAddShow = false
                        this.newAddForm = {
                            tasknumber:'',
                            tasktype:'',
                            taskproperty:'',
                            taskstatus:'',
                            planbegintime:'',
                            planendtime:'',
                            plantaskcontent:'',
                        }
                        this.queryPatrolTaskInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        handleNewAdd(){
            this.newAddShow = true
        },
        resetSearch(){
            this.currentPage = 1
            this.deviceName = ''
            this.isFlaw = ''
            this.queryPatrolTaskInfo()
        },
        queryPatrolTaskInfo(){
            let params = {
                currentPage:this.currentPage,
                pageSize:this.pageSize,
                tasknumber:this.deviceName,
                taskstatus:this.isFlaw
            }
            api_uav.queryPatrolTask(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData = this.tableData.map(x => {
                        x.taskstatus = this.taskStatusArr.filter(y => y.value === Number(x.taskstatus)).length > 0 ? this.taskStatusArr.filter(y => y.value === Number(x.taskstatus))[0].label : x.taskstatus
                        if (x.tasktype) {
                            switch (Number(x.tasktype)) {
                                case 1:
                                    x.tasktypetext = '巡视'
                                    break;
                                case 2:
                                    x.tasktypetext = '缺陷'
                                    break;
                                case 5:
                                    x.tasktypetext = '隐患'
                                    break;
                                default:
                                    break;
                            }
                        }
                        return x
                    })
                    this.totalPage = res.data.total
                    // this.$message.success(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        searchChange(){
            if(!this.deviceName){
                this.currentPage = 1
                this.queryPatrolTaskInfo()
            }
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.queryPatrolTaskInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryPatrolTaskInfo()
        },
        handleClickLine(item){
            let params = {
                tab:'task_airline',
                lineTaskid:item.taskid,
                dispatchId:item.id
                // id:item.id,
            }
            this.$store.commit('clickLabel', params)
            this.$store.commit('clickTab', params.tab)
            // this.$emit('changTab',params)
        },
        handleClickDetail(item){
            let params = {
                tab:'task_patrolDevice',
                patrolTaskid:item.taskid,
                // id:item.id,
            }
            this.$store.commit('clickLabel', params)
            this.$store.commit('clickTab', params.tab)
            // this.$emit('changTab',params)
        }
    }
}
</script>
<style lang="stylus" scoped>
.patroltask
    width: 100%
    height: 100%
    >>>.el-dialog
        .el-dialog__body
            display: flex
            justify-content: center
        .el-select
        .el-input
            width:100%
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 5%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:60px
            div
                >>>.el-input
                    width: 100%
                    height: 36px
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
                .newadd
                    color: #259C9C
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
            .deviceType
                >>>.el-input
                    width: 100%
                    height: 36px
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:80px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 360px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 360px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>