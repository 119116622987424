<template>
    <div class="outDiv">
        <el-row>
            <el-col :span="24">
                <div class="top">
                    <div class="inner">
                        <div v-if="taskOrLine">
                            <label>状态</label>
                            <el-select style="width:75%" v-model="dispatchSelect" clearable  @change="stateChange">
                                <el-option v-for="item in taskStatusArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div v-else>
                            <div class="backIcon" title="返回上一步">
                                <i class="el-icon-arrow-left" @click="backFirstStep"></i>
                            </div>
                            <label>航线名称</label>
                            <el-input class="lineInput" style="margin-left:10px;width:60%;display:inline-block" v-model="deviceName" placeholder="请输入" clearable @keydown.enter.native="queryAirLineInfo" @change="searchChange"></el-input>
                        </div>
                    </div>
                </div>
                <div class="bottom" :style="{'overflow-y' : taskOrLine ? 'hidden' : 'auto'}">
                    <div class="inner" v-if="taskOrLine">
                        <div class="taskItem" v-for="(item) in taskArrs" :key="item.id" @click="clickTaskItem(item.id,item.taskid)"  :class="{'taskActive':curId === item.id}">
                            <el-form label-position="right" :label-width="'40%'">
                                <el-form-item label="任务内容">
                                    <el-tooltip :content="item.plantaskcontent" placement="right" effect="dark" v-if="item.plantaskcontent && item.plantaskcontent.length > 7">
                                        <label>{{item.plantaskcontent.length > 7 ? item.plantaskcontent.slice(0,4) + '...' : item.plantaskcontent}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.plantaskcontent}}</label>
                                </el-form-item>
                                <el-form-item label="开始时间">
                                    <label>{{item.planbegintime}}</label>
                                </el-form-item>
                                <el-form-item label="结束时间">
                                    <label>{{item.planendtime}}</label>
                                </el-form-item>
                                <el-form-item label="任务状态">
                                    <label style="color:#B6F3E0">{{item.taskstatus}}</label>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="noData" v-if="firstNull">
                            <label>暂无数据</label>
                        </div>
                    </div>
                    <div class="inner" v-else>
                        <div class="taskItem2" v-for="(item) in taskArrs" :key="item.id" @click="clickTaskItem2(item.id,item.taskid)"  :class="{'taskActive':curId2 === item.id}">
                            <el-form label-position="right" :label-width="'50%'">
                                <el-form-item label="航线名称">
                                    <el-tooltip :content="item.name" placement="right" effect="dark" v-if="item.name && item.name.length > 7">
                                        <label>{{item.name.length > 7 ? item.name.slice(0,4) + '...' : item.name}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.name}}</label>
                                </el-form-item>
                                <el-form-item label="是否已派工">
                                    <label>{{item.isDispatch}}</label>
                                </el-form-item>
                                <el-form-item label="当前派工">
                                    <el-tooltip :content="item.person" placement="right" effect="dark" v-if="item.person && item.person.length > 7">
                                        <label>{{item.person.length > 7 ? item.person.slice(0,4) + '...' : item.person}}</label>
                                    </el-tooltip>
                                    <label v-else>{{item.person?item.person:'--'}}</label>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="noData" v-if="secondNull">
                            <label>暂无数据</label>
                        </div>
                    </div>
                    <div class="pagination" v-if="taskOrLine && !firstNull">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10,20,50,100]"
                            :page-size="pageSize"
                            :pager-count="5"
                            layout="prev, pager, next"
                            :total="totalPage">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import api_uav from '@/utils/api/api_uav'
export default {
    name:'OutDiv',
    data(){
        return{
            firstNull:false,
            secondNull:false,
            dispatchSelect:'',
            currentPage:1,
            pageSize:5,
            totalPage:0,
            curId:0,
            curTaskId:0,
            deviceTypeArr:[
                {
                    label:'定点',
                    value:0,
                },
                {
                    label:'箱式变压器',
                    value:1,
                },
                {
                    label:'柱上变压器',
                    value:2,
                },
                {
                    label:'开闭所',
                    value:3,
                },
                {
                    label:'环网柜',
                    value:4,
                },
                {
                    label:'分支箱',
                    value:5,
                },
                {
                    label:'配电室',
                    value:6,
                },
                {
                    label:'杆塔',
                    value:7,
                },
                {
                    label:'开关',
                    value:8,
                },
                {
                    label:'其他',
                    value:9,
                },
            ],
            taskStatusArr:[
                {
                    label:'未派单',
                    value:1,
                },
                {
                    label:'已派单',
                    value:2,
                },
                {
                    label:'已接单',
                    value:3,
                },
                {
                    label:'已回执',
                    value:4,
                },
                {
                    label:'归档',
                    value:5,
                }
            ],
            taskArrs:[],
            recordFlag:2,
            taskOrLine:true,
            deviceName:'',
            curId2:0,
        }
    },
    mounted(){
        this.queryTaskInfo()
    },
    methods:{
        backFirstStep(){
            this.$emit('clearAll')
            this.taskOrLine = true
            this.queryTaskInfo()
        },
        queryAirLineInfo(){
            let params = {
                airLineName: this.deviceName,
                patrolTaskId: this.curTaskId
            }
            this.secondNull = true
            api_uav.queryAirLineInfo(params).then(res => {
                if(res.data.code === 200){
                    if(res.data.rows.length < 1){
                    }else{
                        this.secondNull = false
                    }
                    this.taskOrLine = false
                    this.taskArrs = res.data.rows
                    if(this.taskArrs.length > 0){
                        this.curId2 = this.taskArrs[0].id
                        let data = {
                            taskid: this.curTaskId,
                            id:this.curId2
                        }
                        this.$emit('sendTaskId',data)
                    }
                    this.totalPage2 = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        // queryDeviceInfo(){
        //     let params = {
        //         // currentPage: this.currentPage,
        //         // pageSize: this.pageSize,
        //         equipName: this.deviceName,
        //         equipType: this.isFlaw,
        //         lineId: this.curId,
        //         patrolTaskId: this.curTaskId
        //     }
        //     api_uav.queryTaskDetail(params).then(res => {
        //         if(res.data.code === 200){
        //             this.taskOrLine = false
        //             this.taskArrs = res.data.rows
        //             if(this.taskArrs.length > 0){
        //                 this.taskArrs = this.taskArrs.map(x => {
        //                     x.equipType = this.deviceTypeArr.filter(y => y.value === Number(x.equipType))[0].label
        //                     return x
        //                 })
        //             }
        //             this.totalPage = res.data.total
        //         }else{
        //             this.$message.error(res.data.msg)
        //         }
        //     })
        // },
        searchChange(){
            if(!this.deviceName){
                this.queryAirLineInfo()
            }
        },
        stateChange(){
            this.currentPage = 1
            this.queryTaskInfo()
        },
        queryTaskInfo(){
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                taskstatus: this.dispatchSelect,
            }
            this.firstNull = true
            api_uav.queryPatrolTask(params).then(res => {
                if(res.data.code === 200){
                    if(res.data.rows.length < 1){
                    }else{
                        this.firstNull = false
                    }
                    this.taskArrs = res.data.rows
                    if(this.taskArrs.length > 0){
                        this.taskArrs = this.taskArrs.map(x => {
                            x.taskstatus = this.taskStatusArr.filter(y => y.value === Number(x.taskstatus)).length > 0 ? this.taskStatusArr.filter(y => y.value === Number(x.taskstatus))[0].label : x.taskstatus
                            return x
                        })
                    }
                    // this.recordFlag--
                    // if(this.recordFlag > 0){
                        // this.curId = this.taskArrs[0].id
                    this.curTaskId = this.taskArrs[0].taskid
                    // }
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.currentPage = 1
            this.pageSize = val
            this.queryTaskInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryTaskInfo()
        },
        clickTaskItem(id,taskid){
            this.curId = id
            this.curTaskId = taskid
            this.queryAirLineInfo()
            this.$emit('clearAll')
        },
        clickTaskItem2(id){
            this.curId2 = id
            let data = {
                taskid: this.curTaskId,
                id:this.curId2
            }
            this.$emit('sendTaskId',data)
        },
    }
}
</script>
<style lang="stylus" scoped>
>>>.el-form-item__content
>>>.el-form-item__label
    line-height: 5vh !important
.taskItem2:last-child {
    margin-bottom: 15px
}
.noData
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size:15px
    color:white
.outDiv
    overflow-y: hidden
    >>>.el-row
        height: 100%
    >>>.el-col-24
        border-right: 1px solid white
    .top
        height: 10%
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        font-size: 14px
        border-bottom: 1px solid white
        >>>.el-select
            width: 80%
            margin-left: 10px
        >>>.el-select
        >>>.el-input
        >>>.el-input__inner
            height: 36px
        .inner
            width: 90%
            display: inline-flex
            justify-content: center
            align-items: center
            .lineInput
                height: 28px
                >>>.el-input__inner
                    height: 28px
                >>>.el-input__suffix
                    top:-5px !important
            .backIcon
                border-radius: 50px 
                width: 20px
                height: 20px
                background: #209999
                position: absolute
                left: 5px
                top:0.5%
                font-size:20px
                i
                    font-size: 14px
                    position: absolute
                    left 3px
                    top: 4px
                    color: white
                &:hover
                    cursor pointer
    .bottom::-webkit-scrollbar {
                width : 5px;
                height: 1px;
    }
    .bottom::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
        background   : #535353;
    }
    .bottom::-webkit-scrollbar-track {
        box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
        border-radius: 10px;
        background   : #ededed;
    }
    .bottom
        position: relative
        height: 90%
        display: flex
        justify-content: center
        overflow-y: auto
        .pagination
            display: block
            position: absolute
            bottom: 5%
            right: 5px
            >>>.el-pager li{
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                min-width:23px !important;
                height: 23px !important;
                margin: 0 2px 0 2px !important;
                line-height:23px !important
            }
            >>>.el-pagination button, .el-pagination span:not([class*=suffix]){
                min-width:23px !important;
                height: 23px !important;
                line-height:20px !important;
            }
            >>>.el-pager li:hover{
                color: #199999 !important;
            }
            >>>.el-pager li.active{
                background: #199999;
                color: white !important;
            }
            >>>.el-pagination .btn-next, .el-pagination .btn-prev{
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                width: 20px !important;
                margin: 0 4px !important;
            }
            >>>.el-pagination .btn-next, .el-pagination .btn-prev :hover{
                color: #199999 !important;
            }
        .inner
            height: 100%
            width: 90%
        .taskActive
            border: 1px solid #B6F3E0 !important
        .taskItem
        .taskItem2
            height: 15%
            width: 100%
            border: 1px solid white
            display: inline-flex
            justify-content: center
            margin-top: 5%
            >>>.el-form
                height: 100%
                width: 100%
            >>>.el-form-item
                margin: 0 !important
                height: 20%;
                display: flex;
            >>>.el-form-item__content
                margin-left: 10% !important
            >>>.el-form-item__label
                color: white
        .taskItem2
            height: 13%
            >>>.el-form-item
                height: 25% !important
            

</style>