<template>
    <div class="patroltask">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>设备名称</label>
                        <el-input v-model="deviceName" placeholder="请输入名称" @change="searchChange" clearable @keydown.enter.native="goSearch"></el-input>
                    </div>
                    <div class="deviceType">
                        <label>设备类型</label>
                        <el-select v-model="isFlaw">
                            <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset" @click="resetSearch">重置</el-button>
                        <el-button class="newAdd" @click="handleNewAdd">新增</el-button>
                        <!-- <el-button class="delete" style="border:1px solid red;color:red;background:white" @click="handleMulDel">批量删除</el-button> -->
                    </div>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            resizable
                            highlight-current-row
                            :data="tableData"
                            style="width:100%"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            max-height="calc(100vh - 250px)"
                            :row-style="{height: '50px'}"
                            tooltip-effect="light"
                            @selection-change="handleDelSelect"
                            >
                            <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                width="auto"
                                sortable>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            class="newAddDevice"
            title="新增巡检设备"
            center
            :visible.sync="newAddDialogShow"
            v-drag
            width="70%">
            <div style="width:100%;">
                <el-row style="width:100%" class="top-row">
                    <div style="width: auto" class="dialog_typeDiv">
                        <el-form :inline="true" class="demo-form-inline" label-width="80px" ref="form">
                            <!-- <el-form-item label="设备类型" prop="newDeviceType">
                                <el-select v-model="newDeviceForm.newDeviceType">
                                    <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"  style="width:180px"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item label="航线名称" prop="newDeviceName">
                                <div class="dialog_type" >
                                    <el-input v-model="newAddSearchKey" placeholder="请输入" style="width:180px" clearable @change="newAddSearchChange" @keydown.enter.native="handleNewAdd(1)"></el-input>
                                </div>
                            </el-form-item>
                            <el-button class="dialog_typeBtn" @click="handleNewAdd(1)">查询</el-button>
                        </el-form>
                    </div>
                </el-row>
                <el-row style="width:100%" class="bottom-row">
                    <div style="width:100%;">
                        <el-table
                            class="myTable"
                            resizable
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#259C9C',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            highlight-current-row
                            :data="newtableData"
                            stripe
                            border
                            max-height="410px"
                            height="410px"
                            tooltip-effect="light"
                            :row-style="{height: '40px'}"
                            :cell-style="{padding: '0.505%'}"
                            row-key="id"
                            @selection-change="handleSelectionChange"
                            style="width: 100%">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="航线名称"
                            align="center"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="oldFileName"
                            align="center"
                            label="kml航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="kmzOldFileName"
                            align="center"
                            label="kmz航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="txtOldFileName"
                            align="center"
                            label="道通航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <!-- <el-table-column
                            prop="isDispatch"
                            align="center"
                            label="是否已派工"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="person"
                            align="center"
                            label="当前派工"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column> -->
                        </el-table>
                    </div>
                </el-row>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="currentPage2"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize2"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage2">
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goDispatch">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
export default {
    name:'PatrolDevice',
    props:{
        patrolTaskId:{

        },
    },
    data(){
        return{
            newAddSearchKey:'',
            newtableData: [],
            newAddDialogShow: false,
            tableMulSelect: [],
            tableDelSelect: [],
            deviceName:'',
            isFlaw:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            currentPage2: 1,
            pageSize2:10,
            totalPage2:0,
            deviceTypeArr:[
                {
                    label:'定点',
                    value:0,
                },
                {
                    label:'箱式变压器',
                    value:1,
                },
                {
                    label:'柱上变压器',
                    value:2,
                },
                {
                    label:'开闭所',
                    value:3,
                },
                {
                    label:'环网柜',
                    value:4,
                },
                {
                    label:'分支箱',
                    value:5,
                },
                {
                    label:'配电室',
                    value:6,
                },
                {
                    label:'杆塔',
                    value:7,
                },
                {
                    label:'开关',
                    value:8,
                },
                {
                    label:'其他',
                    value:9,
                },
            ],
            tableColumns:[
                {
                    label:'航线名称',
                    prop:'lineName',
                },
                {
                    label:'设备名称',
                    prop:'equipName',
                },
                {
                    label:'设备类型',
                    prop:'equipType',
                },
                {
                    label:'巡检顺序号',
                    prop:'sort',
                },
            ],
            tableData: [],
        }
    },
    computed:{
        taskId(){
            return this.$store.state.patrolTaskid
        }
    },
    mounted(){
        this.queryPatrolDeviceInfo()
    },
    methods:{
        handleMulDel(){

        },
        newAddSearchChange(){
            if(!this.newAddSearchKey){
                this.currentPage2 = 1
                this.handleNewAdd()
            }
        },
        goDispatch(){
            if(this.tableMulSelect.length < 1){
                this.$message.warning('请先选择要添加的航线')
                return
            }
            // console.log(this.tableMulSelect)
            let params = {
                airLineIds: this.tableMulSelect.map(x => x.id).join(','),
                patroltaskid: this.taskId
            }
            api_uav.queryAirLineDeviceByMulAirId(params).then(res => {
                if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    this.newAddDialogShow = false
                    this.queryPatrolDeviceInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleDelSelect(val){
            this.tableDelSelect = val
        },
        handleSelectionChange(val){
            this.tableMulSelect = val
        },  
        goSearch(){
            this.currentPage = 1
            this.queryPatrolDeviceInfo()
        },
        searchChange(){
            if(!this.deviceName){
                this.currentPage = 1
                this.queryPatrolDeviceInfo()
            }
        },
        handleNewAdd(flag){
            if(flag !== 1){
                this.newAddDialogShow = true
            }
            let params = {
                currentPage: this.currentPage2,
                pageSize: this.pageSize2,
                searchValue: this.newAddSearchKey,
            }
            api_uav.queryLineInfo(params).then(res => {
                if(res.data.code === 200){
                    // this.$message.success('')
                    this.newtableData = res.data.rows
                    this.totalPage2 = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        resetSearch(){
            this.currentPage = 1
            this.deviceName = ''
            this.isFlaw = ''
            this.queryPatrolDeviceInfo()
        },
        queryPatrolDeviceInfo(){
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                equipName: this.deviceName,
                equipType: this.isFlaw,
                patrolTaskId: this.taskId
            }
            // console.log(params)
            api_uav.queryTaskDetail(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData = this.tableData.map(x => {
                        x.equipType = this.deviceTypeArr[x.equipType].label
                        x.sort = x.sort ? x.sort : '--'
                        return x
                    })
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.currentPage = 1
            this.pageSize = val
            this.queryPatrolDeviceInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryPatrolDeviceInfo()
        },
        handleSizeChange2(val){
            this.currentPage2 = 1
            this.pageSize2 = val
            this.handleNewAdd()
        },
        handleCurrentChange2(val){
            this.currentPage2 = val
            this.handleNewAdd()
        },
    }
}
</script>
<style lang="stylus" scoped>
>>>.el-table__body-wrapper
    border-right: 1px solid rgba(73, 92, 106, 0.2) !important
>>>.el-table__empty-block
    height: 37.5vh !important
.patroltask
    width: 100%
    height: 100%
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 5%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:60px
            div
                >>>.el-input
                    width: 100%
                    height: 36px
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
                .newAdd
                    background: rgba(37,156,156,0.1);
                    border: 1px solid #259C9C;
                    color: #259C9C
                >>>.el-input
                    width: 100%
                    height: 36px
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:80px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 350px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
.newAddDevice
    .pagination
        position: absolute
        right: 15px
        margin-top: 5px
.myTable::-webkit-scrollbar {
            width : 5px;
            height: 1px;
}
.myTable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
    background   : #535353;
}
.myTable::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
    border-radius: 10px;
    background   : #ededed;
}
</style>