<template>
    <div class="nav">
        <el-menu
            class="el-menu-vertical-demo"
            :router="true"
            :default-active="activeIndex"
            :default-openeds="openIndexs"
            @open="handleOpen"
            @close="handleClose">
            <el-submenu index="1">
                <template slot="title">
                    <img src="@images/nav/first_g.png" v-if="curRouter === 1 ">
                    <img src="@images/nav/first_w.png" v-else>
                    <!-- <i class="el-icon-place"></i> -->
                    <span>自主巡检</span>
                    <img class="down-icon" src="@images/nav/cword2.png" v-if="curRouter === 1 ">
                    <img class="down-icon" src="@images/nav/cword.png" v-else>
                </template>
                <el-menu-item index="/task_manage">巡检任务管理</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
                <template slot="title">
                    <!-- <i class="el-icon-menu"></i> -->
                    <img src="@images/nav/second_g.png" v-if="curRouter === 2 ">
                    <img src="@images/nav/second_w.png" v-else>
                    <span slot="title">巡检成果</span>
                    <img class="down-icon" src="@images/nav/cword2.png" v-if="curRouter === 2 ">
                    <img class="down-icon" src="@images/nav/cword.png" v-else>
                </template>
                <el-menu-item index="/picture_manage">巡检照片管理</el-menu-item>
                <el-menu-item index="/map">成果可视化管理</el-menu-item>
                <el-menu-item index="/live_video">视频直播</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">
                    <!-- <i class="el-icon-s-marketing"></i> -->
                    <img src="@images/nav/third_g.png" v-if="curRouter === 3">
                    <img src="@images/nav/third_w.png" v-else>
                    <span slot="title">数据台账</span>
                    <img class="down-icon" src="@images/nav/cword2.png" v-if="curRouter === 3 ">
                    <img class="down-icon" src="@images/nav/cword.png" v-else>
                </template>
                <el-menu-item index="/line_manage">航线管理</el-menu-item>
                <el-menu-item index="/uav_manage">无人机管理</el-menu-item>
            </el-submenu>
            <!-- <el-submenu index="4">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span slot="title">统计报表</span>
                </template>
                <el-menu-item index="4-1">缺陷管理</el-menu-item>
                <el-menu-item index="/defect_analysis">缺陷分析</el-menu-item>
            </el-submenu> -->
            <el-submenu index="5">
                <template slot="title">
                    <!-- <i class="el-icon-setting"></i> -->
                    <img src="@images/nav/fourth_g.png" v-if="curRouter === 4 ">
                    <img src="@images/nav/fourth_w.png" v-else>
                    <span slot="title">系统管理</span>
                    <img class="down-icon" src="@images/nav/cword2.png" v-if="curRouter === 4 ">
                    <img class="down-icon" src="@images/nav/cword.png" v-else>
                </template>
                <el-menu-item index="/app_user_manage">APP用户管理</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
export default {
    name:'Nav',
    data(){
        return{
            activeIndex:'/task_manage',
            openIndexs:['1','2','3','4','5']
        }
    },
    mounted(){
        let recordPath = sessionStorage.getItem('recordPath')
        switch (recordPath) {
            case '/task_airline':
            case '/task_airlinedevice':
            case '/task_patrolDevice':
                this.activeIndex = '/task_manage'
                break;
            default:
                this.activeIndex = recordPath
                break;
        }
    },
    computed:{
        curRouter(){
            let result = 0
            switch (this.$route.path) {
                case '/task_manage':
                    result = 1
                    break;
                case '/picture_manage':
                case '/map':
                case '/live_video':
                    result = 2
                    break;
                case '/line_manage':
                case '/uav_manage':
                    result = 3
                    break;
                case '/app_user_manage':
                    result = 4
                    break;
                default:
                    break;
            }
            return result
        }
    },
    methods: {
        handleOpen(key, keyPath) {
        //   console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
        //   console.log(key, keyPath);
        }
    }
}
</script>
<style lang="stylus" scoped>
.nav
    height: 100%
    img
        width: 18px
        height: 18px
        margin-right: 10px
    .down-icon
        position: absolute
        right: 0
        top: 16px
    .is-opened
        .down-icon
            transform: rotateZ(90deg)
    >>>.el-menu
        background-color: transparent !important
        padding: 0 10px
    >>>.el-submenu__icon-arrow
        display: none
    >>>.el-menu-item, .el-submenu__title
        height: 5vh
        line-height: 5vh
    >>>.el-submenu__title
        font-size: 16px
        font-family: Source Han Sans CN-Bold, Source Han Sans CN  !important
        font-weight: bold  !important
        color: #495C6A !important
        &:hover
            background: rgba(31,153,153,0.1);
            border-radius: 5px 5px 5px 5px;
    >>>.el-menu-item.is-active
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #1F9999;
        background: rgba(31,153,153,0.1);
        border-radius: 5px 5px 5px 5px;
    >>>.el-menu-item
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #7F8F9B;
        margin : 10px 0
        &:hover
            background: rgba(31,153,153,0.1);
            border-radius: 5px 5px 5px 5px;
    .is-active
        >>>.el-submenu__title
            color: #1F9999 !important
</style>