<template>
    <div class="patroltask">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>无人机型号</label>
                        <el-input v-model="uavXh" placeholder="请输入名称" clearable @keydown.enter.native="goSearch" @change="seachChange"></el-input>
                    </div>
                    <div class="deviceType">
                        <label>飞行状态</label>
                        <el-input v-model="flyState" placeholder="请输入" clearable  @keydown.enter.native="goSearch" @change="seachChange"></el-input>
                        <!-- <el-select v-model="flyState">
                            <el-option label="是" value="true"></el-option>
                            <el-option label="否" value="false"></el-option>
                        </el-select> -->
                    </div>
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset" @click="resetSearch">重置</el-button>
                    </div>
                </div>
                <div class="top_second">
                    <el-button @click="handleNewAdd">新增</el-button>
                    <el-button style="margin-left:90px;border:1px solid red;color:red;background:white" @click="handleDeleteMost">批量删除</el-button>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            ref="multipleTable"
                            resizable
                            highlight-current-row
                            :data="tableData"
                            row-key="id"
                            style="width:100%;height:100%"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            max-height="calc(100vh - 250px)"
                            :row-style="{height: '50px'}"
                            :cell-style="{padding: '0.505%'}"
                            tooltip-effect="light"
                            @selection-change="handleSelectionChange"
                            >
                            <el-table-column
                                show-overflow-tooltip
                                type="selection"
                                :reserve-selection="true" 
                                width="55">
                            </el-table-column>
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                min-width="120"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                align="center"
                                label="操作"
                                width="170">
                                <template slot-scope="scope">
                                    <el-button @click="handleClickLine(scope.row)" type="text" size="small" class="greenBtn">修改</el-button>
                                    <el-button @click="handleClickDetail(scope.row)" type="text" size="small" class="redBtn">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="10"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :title="dialogTitle"
            center
            v-drag
            :visible.sync="newAddShow"
            width="50%">
            <div style="width:80%">
                <el-form ref="form" :model="newAddForm" :rules="rules" label-width="100px">
                    <el-form-item label="无人机编号">
                        <el-input v-model="newAddForm.bh" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="无人机型号" prop="xh">
                        <el-input v-model="newAddForm.xh" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="厂家">
                        <!-- <el-select v-model="newAddForm.equipType">
                            <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select> -->
                        <!-- <el-input v-model="newAddForm.cj" placeholder="请输入"></el-input> -->
                        <el-select v-model="newAddForm.cj">
                            <el-option label="道通无人机" value="道通"></el-option>
                            <el-option label="大疆无人机" value="大疆"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="机翼类型">
                        <el-input v-model="newAddForm.jylx" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="购买日期">
                        <!-- <el-input v-model="newAddForm.gmrq" placeholder="请输入"></el-input> -->
                        <el-date-picker
                            v-model="newAddForm.gmrq"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="维护单位">
                        <el-input v-model="newAddForm.whdw" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="飞行状态">
                        <!-- <el-select v-model="newAddForm.fxzt">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select> -->
                        <el-input v-model="newAddForm.fxzt" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="序列号" prop="xlh">
                        <el-input v-model="newAddForm.xlh" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goDispatch('form')">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
export default {
    name:'TaskManager',
    data(){
        return{
            rules:{
                xlh: [
                   { required: true, message: '请输入序列号', trigger: 'blur' }
                ],
                xh: [
                   { required: true, message: '请输入无人机型号', trigger: 'blur' }
                ],
            },
            dialogTitle:'新增无人机',
            uavXh:'',
            flyState:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            newAddShow:false,
            newAddForm:{
                bh:'',
                xh:'',
                cj:'',
                jylx:'',
                gmrq:'',
                whdw:'',
                fxzt:'',
                xlh:'',
            },
            tableColumns:[
                {
                    label:'无人机编号',
                    prop:'bh',
                },
                {
                    label:'无人机型号',
                    prop:'xh',
                },
                {
                    label:'厂家',
                    prop:'cj',
                },
                {
                    label:'机翼类型',
                    prop:'jylx',
                },
                {
                    label:'购买日期',
                    prop:'gmrq',
                },
                {
                    label:'维护单位',
                    prop:'whdw',
                },
                {
                    label:'飞行状态',
                    prop:'fxzt',
                },
                {
                    label:'序列号',
                    prop:'xlh',
                },
            ],
            tableData: [],
            flag:1,
            selectArr:[],

        }
    },
    mounted(){
        this.queryUavInfo()
    },
    methods:{
        goSearch(){
            this.currentPage = 1
            this.queryUavInfo()
        },
        handleNewAdd(){
            this.newAddShow = true
            this.dialogTitle = '新增无人机'
            this.newAddForm = {
                bh:'',
                xh:'',
                cj:'',
                jylx:'',
                gmrq:'',
                whdw:'',
                fxzt:'',
                xlh:'',
            }
        },
        goDispatch(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = this.newAddForm
                if(this.flag === 2){
                    this.axios.put('/api/patrol/info',params, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
                        if(res.data.code === 200){
                            this.$message.success(res.data.msg)
                            this.queryUavInfo()
                            this.newAddShow = false
                            this.newAddForm = {
                                bh:'',
                                xh:'',
                                cj:'',
                                jylx:'',
                                gmrq:'',
                                whdw:'',
                                fxzt:'',
                                xlh:'',
                            }
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }else{
                    api_uav.addUavInfo(params).then(res => {
                        if(res.data.code === 200){
                            this.$message.success(res.data.msg)
                            this.queryUavInfo()
                            this.newAddShow = false
                            this.newAddForm = {
                                bh:'',
                                xh:'',
                                cj:'',
                                jylx:'',
                                gmrq:'',
                                whdw:'',
                                fxzt:'',
                                xlh:'',
                            }
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        seachChange(){
            if(!this.uavXh || !this.flyState){
                this.queryUavInfo()
            }
        },
        resetSearch(){
            this.currentPage = 1
            this.uavXh = ''
            this.flyState = ''
            this.queryUavInfo()
        },
        queryUavInfo(){
            let params = {
                currentPage : this.currentPage,
                pageSize : this.pageSize
            }
            if(this.uavXh){
                params.xh = this.uavXh
            }
            if(this.flyState){
                params.fxzt = this.flyState
            }
            api_uav.queryUavInfo(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.totalPage = res.data.total
                    // this.$message.success(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.queryUavInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryUavInfo()

        },
        handleClickLine(item){
            this.newAddShow = true
            this.dialogTitle = '无人机信息修改'
            this.newAddForm = JSON.parse(JSON.stringify(item))
            this.flag = 2
        },
        handleSelectionChange(val){
            this.selectArr = val
        },
        handleDeleteMost(){
            if(this.selectArr.length < 1){
                this.$message.warning('请先勾选要删除的数据')
                return 
            }
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = this.selectArr.map(x => x.id).join(',')
                api_uav.deleteUavInfo(ids).then(res => {
                    if(res.data.code === 200){
                        this.$refs.multipleTable.clearSelection();
                        this.$message.success(res.data.msg)
                        this.queryUavInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
        },
        handleClickDetail(item){
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api_uav.deleteUavInfo(item.id).then(res => {
                    if(res.data.code === 200){
                        this.$message.success(res.data.msg)
                        this.queryUavInfo()
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {         
            });
            return false;
            
            
        }
    }
}
</script>
<style lang="stylus" scoped>
.patroltask
    width: 100%
    height: 100%
    >>>.el-dialog
        .el-dialog__body
            display: flex
            justify-content: center
        .el-select
        .el-input
            width:100%
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 5%
                >>>.el-input
                    width: 50%
                    height: 36px
                >>>.el-select
                    width: 50%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:45px
            margin: 10px 0
            >>>.el-button
                    position: absolute
                    left: 25px
                    bottom: 0px
                    width: 80px;
                    height: 32px;
                    background: rgba(37,156,156,0.1);
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #259C9C;
                    display: flex
                    justify-content: center
                    align-items: center
                    color: #259C9C;
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
            .deviceType
                >>>.el-input
                    width: 60%
                    height: 36px
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:110px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
                max-height: calc(100vh - 330px);
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 330px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>