<template>
    <!-- <div class="patrolTask"> -->
        <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick($event.name)" editable type="card"  @edit="handleTabsEdit">
            <el-tab-pane class="task-manage" label="巡检任务管理" name="task_manage" :closable="false"></el-tab-pane>
            <!-- <el-tab-pane label="航线" name="1">
                <air-line
                 ref="airLine"
                 @changTab="changTab"
                 :patrolTaskId="patrolTaskId"
                 ></air-line>
            </el-tab-pane>
            <el-tab-pane label="航线设备" name="2">
                <air-line-device ref="airlineDevice"></air-line-device>
            </el-tab-pane>
            <el-tab-pane label="巡检设备" name="3">
                <patrol-device
                    ref="patrolDevice" 
                    :patrolTaskId="patrolTaskId"  
                ></patrol-device>
            </el-tab-pane> -->
            <el-tab-pane
                v-for="(item) in editableTabs"
                :key="item.name" 
                :name="item.name"
                :label="item.title"
            >
            </el-tab-pane>
        </el-tabs>
    <!-- </div> -->
</template>
<script>
import TaskManager from '@views/patrolSelf/TaskManage'
import AirLine from '@views/patrolSelf/AirLine'
import AirLineDevice from '@views/patrolSelf/AirLineDevice'
import PatrolDevice from '@views/patrolSelf/PatrolDevice'
import api_uav from '@utils/api/api_uav'
export default {
    name:'patrolTask',
    components:{
        TaskManager,
        AirLine,
        AirLineDevice,
        PatrolDevice
    },
    data(){
        return{
            activeName:'task_manage',
            patrolTaskId:'',
            curId:'',
            editableTabs:[
                // {
                //     title: '航线',
                //     name: 'task_airline',
                //     content: ''
                // },
                // {
                //     title: '航线设备',
                //     name: 'task_airlinedevice',
                //     content: ''
                // },
                // {
                //     title: '巡检设备',
                //     name: 'task_patrolDevice',
                //     content: ''
                // },
            ],
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.$refs.tabs.$children[0].$refs.tabs[0].children[0].style.display = 'none'
        });
        this.tabChange()
        if(this.recordTabs.indexOf(this.$route.path) < 0){
            this.$router.push('/task_manage')
        }

    },
    watch:{
        newActiveName(nv,ov){
            this.activeName = nv
            this.tabChange()
        },
        routerName(nv,ov){
            this.activeName = nv
            this.tabChange()
        }
    },
    computed:{
        routerName(){
            return this.$route.name
        },
        newActiveName(){
            return this.$store.state.tab
        },
        recordTabs(){
            return this.$store.state.recordTab
        }
    },
    methods:{
        tabChange(){
            this.editableTabs = this.recordTabs.map(x => {
                let title = ''
                switch (x) {
                    case 'task_airline':
                        title = '航线'
                        break;
                    case 'task_airlinedevice':
                        title = '航线设备'
                        break;
                    case 'task_patrolDevice':
                        title = '巡检设备'
                        break;
                    default:
                        break;
                }
                return {
                    title: title,
                    name: x,
                    content: ''
                }
            })
        },
        handleTabsEdit(targetName, action) {
            if (action === 'add') {
            // let newTabName = ++this.tabIndex + '';
            // this.editableTabs.push({
            //     title: '航线设备',
            //     name: newTabName,
            //     content: ''
            // });
            // this.activeName = newTabName;
            }
            if (action === 'remove') {
                // console.log(targetName)
                this.$store.commit('clickTab', 'task_manage')
                this.$store.commit('deleteTab',targetName)
                this.tabChange()
                if(this.$route.name !== 'task_manage'){
                    this.$router.push({name:'task_manage'})
                }
            // let tabs = this.editableTabs;
            // let activeName = this.activeName;
            // if (activeName === targetName) {
            //     tabs.forEach((tab, index) => {
            //     if (tab.name === targetName) {
            //         let nextTab = tabs[index + 1] || tabs[index - 1];
            //         if (nextTab) {
            //         activeName = nextTab.name;
            //         }
            //     }
            //     });
            // }
            
            // this.activeName = activeName;
            // this.editableTabs = tabs.filter(tab => tab.name !== targetName);
            }
        },
        changTab(params){
            this.activeName = params.tab
            this.patrolTaskId = params.taskid
            this.curId = params.id
            switch (this.activeName) {
                case '1':
                    this.$refs.airLine.queryAirLineInfo(this.patrolTaskId,this.curId)
                    break;
                case '2':
                    this.$refs.airlineDevice.queryInfo(this.curId)
                    break;
                case '3':
                    this.$refs.patrolDevice.queryPatrolDeviceInfo(this.patrolTaskId)
                    break;
                default:
                    break;
            }
        },
        handleClick(name) {
            // console.log(name)
            this.$store.commit('clickTab', name)
        }
    }
}
</script>
<style lang="stylus" scoped>
>>>.el-tabs__new-tab
    display: none
// >>>.el-icon-close
//     display: none !important
>>>.el-tabs__item.is-active
    color: #1F9999 !important;
.patrolTask
    height: 100%
    width: 100%
    >>>.el-tabs
        border-radius: 8px 0px 0px 8px;
        .el-tabs__content
            height: calc(100% - 40px)
            padding:0 !important
        .el-tabs__item
            &:hover
             color: #1F9999 !important
        .el-tabs__active-bar
            background-color: #1F9999
        .el-tabs__nav-wrap
        .el-tabs__header
            border-radius: 8px
    >>>.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active
        color: #1F9999 !important;
    >>>.el-tabs__header
        margin: 0 !important
        padding: 0 0px
    // >>>.el-tabs
    // >>>.el-tab-pane
        // height: 100%
    >>>.el-dialog
        .el-dialog__header
            text-align: left
        .el-dialog__body
            min-height: 10vh
            display: flex
            justify-content: center
            align-items: center
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
</style>