<template>
    <div class="patrolTask">
        <task-nav ></task-nav>
        <div style="height:calc(100% - 50px)">
            <router-view></router-view>
        </div>
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick" editable type="card"  @edit="handleTabsEdit">
            <el-tab-pane label="巡检任务管理" name="0" :closable="false">
                <task-manager
                    @changTab="changTab"
                ></task-manager>
            </el-tab-pane>
            <el-tab-pane label="航线" name="1">
                <air-line
                 ref="airLine"
                 @changTab="changTab"
                 :patrolTaskId="patrolTaskId"
                 ></air-line>
            </el-tab-pane>
            <el-tab-pane label="航线设备" name="2">
                <air-line-device ref="airlineDevice"></air-line-device>
            </el-tab-pane>
            <el-tab-pane label="巡检设备" name="3">
                <patrol-device
                    ref="patrolDevice" 
                    :patrolTaskId="patrolTaskId"  
                ></patrol-device>
            </el-tab-pane>
        </el-tabs> -->
    </div>
</template>
<script>
import TaskManager from '@views/patrolSelf/TaskManage'
import AirLine from '@views/patrolSelf/AirLine'
import AirLineDevice from '@views/patrolSelf/AirLineDevice'
import PatrolDevice from '@views/patrolSelf/PatrolDevice'
import TaskNav from '@views/patrolSelf/TaskNav'
import api_uav from '@utils/api/api_uav'
export default {
    name:'patrolTask',
    components:{
        TaskManager,
        AirLine,
        AirLineDevice,
        PatrolDevice,
        TaskNav
    },
    data(){
        return{
            activeName: '0',
            patrolTaskId:'',
            curId:'',
            editableTabs:[],
        }
    },
    mounted(){

        
    },
    methods:{
        changTab(params){
            this.activeName = params.tab
            this.patrolTaskId = params.taskid
            this.curId = params.id
            this.$router.push(this.activeName)
            switch (this.activeName) {
                case '/task_airline':
                    this.$refs.airLine.queryAirLineInfo(this.patrolTaskId,this.curId)
                    break;
                case '/task_airlinedevice':
                    this.$refs.airlineDevice.queryInfo(this.curId)
                    break;
                case '/task_patrolDevice':
                    this.$refs.patrolDevice.queryPatrolDeviceInfo(this.patrolTaskId)
                    break;
                default:
                    break;
            }
        },
        handleClick(tab, event) {
        }
    }
}
</script>
<style lang="stylus" scoped>
.patrolTask
    height: 100%
    width: 100%
    >>>.el-tabs
        border-radius: 8px 0px 0px 8px;
        .el-tabs__content
            height: calc(100% - 40px)
            padding:0 !important
        .el-tabs__item
            &:hover
             color: #1F9999 !important
        .el-tabs__active-bar
            background-color: #1F9999
        .el-tabs__nav-wrap
        .el-tabs__header
            border-radius: 8px
    >>>.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active
        color: #1F9999;
    >>>.el-tabs__header
        margin: 0 !important
        padding: 0 0px
    // >>>.el-tabs
    // >>>.el-tab-pane
    //     height: 100%
    >>>.el-dialog
        .el-dialog__header
            text-align: left
        .el-dialog__body
            min-height: 10vh
            display: flex
            justify-content: center
            align-items: center
        .el-button--primary
            width: 80px;
            color: white
            display: inline-flex
            justify-content: center
            align-items: center
            height: 32px;
            background: #259C9C;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
</style>