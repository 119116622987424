/*
 * @Author: your name
 * @Date: 2020-06-03 09:45:08
 * @LastEditTime: 2020-06-03 14:39:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \webapp\src\utils\api\api_bigScreen.js
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "/web"

class UavApi {
    // -------------------------------自主巡检------------------------------
    //1.巡检任务管理
    //选择航线批量新增巡视范围信息
    queryAirLineDeviceByMulAirId(params){
        let url = '/patrol/equip/addBatchByAirLine'
        return http.post(url,params)
    }
    //查询巡检任务
    queryPatrolTask(params){
        let url = `/patrol/maininfo/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            tasknumber: params.tasknumber,
            taskstatus: params.taskstatus,
        }
        return http.get(url, obj)
    }
    //新增巡检任务
    newAddPatrolTask(params){
        return http.post('/patrol/maininfo',params)
    }
    //2.航线
    //查询航线信息
    queryAirLineInfo(params){
        let url = `/patrol/equip/getAirLineListByTaskId`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            airLineName: params.airLineName,
            patrolTaskId: params.patrolTaskId,
        }
        return http.get(url, obj)
    }
    //派工
    handleDispatch(params){
        let url = '/patrol/dispatch'
        return http.post(url,params)
    }
    //查询所有用户信息
    queryUserList(params){
        let url = `/system/user/list`
        if(params && params.personId){
            url += `?userId=${params.personId}`
        }
        return http.get(url)
    }
    //取消派工
    cancelDispatch(ids){
        let url = `/patrol/dispatch/${ids}`
        return http.delete(url)
    }
    //4.巡检设备
    queryTaskDetail(params){
        let url = `/patrol/equip/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            equipName: params.equipName,
            equipType: params.equipType,
            patroltaskid: params.patrolTaskId,
            id: params.lineId,
        }
        return http.get(url, obj)
    }


    //--------------------------------数据台账------------------------------
    //查询航线信息
    queryLineInfo(params){
        let url = `/patrol/line/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            name: params.searchValue,
        }
        return http.get(url, obj)
    }
    //新增航线信息
    addLineInfo(params){
        return http.post('/patrol/line',params)
    }
    //删除航线信息
    deleteLineInfo(ids){
        return http.delete(`/patrol/line/${ids}`)
    }
    //上传航线文件
    uploadLineFile(params){
        return http.post(`/patrol/line/upload`,params)
    }


    //新增航线设备
    addLineDeviceInfo(params){
        return http.post(`/airline/equip`,params)
    }
    //删除航线设备
    deleteLineDeviceInfo(ids){
        return http.delete(`/airline/equip/${ids}`)
    }
    //查询所有航线设备信息
    queryAllLineDeviceInfo(){
        let url = `/airline/equip/listAll`
        return http.get(url)
    }
    //图片查询设备
    getPictureEquipByAirLineId(params){
        let url = `/airline/equip/getEquipByAirLineId/page`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            equipName: params.equipName,
            equipType: params.equipType,
            airLineId: params.ids,
        }
        return http.get(url, obj)
    } 
    //地图查询设备
    getEquipByAirLineId(params){
        let url = `/airline/equip/getEquipByAirLineId`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            equipName: params.equipName,
            equipType: params.equipType,
            airLineId: params.ids,
        }
        return http.get(url, obj)
    }   
    queryLineDeviceInfo(params){
        let url = `/airline/equip/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            equipName: params.equipName,
            equipType: params.equipType,
            airLineId: params.ids,
        }
        return http.get(url, obj)
    }
    //查询无人机信息
    queryUavInfo(params){
        let url = `/patrol/info/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            xh: params.xh,
            fxzt: params.fxzt,
        }
        return http.get(url, obj)
    }
    //新增无人机信息
    addUavInfo(params){
        return http.post('/patrol/info',params)
    }
    //删除无人机信息
    deleteUavInfo(ids){
        return http.delete(`/patrol/info/${ids}`)
    }


    //------------------------------------巡检成果-------------------------------------
        // -----------------------------巡检照片管理----------------------------
        // 查询同自主巡检的任务查询
    


        //获取照片
        getPictureInfo(params){
            let url = `/patrol/picture/getPictureByTaskIdAndAirLineId`
            let obj = {
                airLineId: params.airLineId,
                equipId: params.equipId,
                patroltaskId: params.patroltaskId,
            }
            return http.get(url, obj)
        }
        // 批量下载照片
        downPictureByTaskIdAndAirLineId(params) {
            let url = `/patrol/picture/downPictureByTaskIdAndAirLineId`
            let obj = params
            return http.post(url, obj)
        }

    // -----------------------------------用户管理---------------------------------------
    //查询用户列表
    queryAppUser(params){
        let url = `/system/user/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            userName: params.userName,
            deptName: params.deptName,
        }
        return http.get(url, obj)
    }
    // 新增用户
    addNewAppUser(parmas){
        return http.post(`/system/user`,parmas)
    }
    //删除用户
    deleteAppUser(ids){
        return http.delete(`/system/user/${ids}`)
    }
    //----------------------------视频直播相关数据------------------
    queryVideoInfo(params){
        let url = `/vedio/info/list`
        let obj = {
            pageNum: params.currentPage,
            pageSize: params.pageSize,
            name: params.name,
            createTime: params.createTime,
        }
        return http.get(url, obj)
    }
    addVideoInfo(params){
        return http.post(`/vedio/info`,params)
    }
    deleteVideoInfo(ids){
        return http.delete(`/vedio/info/${ids}`)
    }
    // AI分析接口
    handleStartAI(params){
        let url = '/patrol/picture/ai'
        return http.post(url, params)
    }
    getAIResult(params) {
        let url = '/patrol/picture/ai'
        return http.get(url, params)
    }
}
export default new UavApi()