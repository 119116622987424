<template>
    <div class="airline">
        <div class="rightContent">
            <div style="height:100%">
                <div class="top_first">
                    <div>
                        <label>航线名称</label>
                        <el-input v-model="airlineName" placeholder="请输入名称" clearable @change="searchChange"  @keydown.enter.native="goSearch"></el-input>
                    </div>
                    <div class="Btn">
                        <el-button class="search" @click="goSearch">查询</el-button>
                        <el-button class="reset"  @click="resetSearch">重置</el-button>
                        <el-button class="newAdd" @click="handleNewAdd">新增</el-button>
                    </div>
                </div>
                <div class="bottom">
                    <div class="inner">
                        <el-table
                            resizable
                            highlight-current-row
                            :data="tableData"
                            style="width:100%"
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#000000',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                            max-height="calc(100vh - 250px)"
                            :row-style="{height: '40px'}"
                            :cell-style="{padding: '0.505%'}"
                            tooltip-effect="light"
                            >
                            <el-table-column v-for="(item) in tableColumns" :key="item.id"
                                align="center"
                                show-overflow-tooltip
                                :label="item.label"
                                :prop="item.prop"
                                width="auto"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                align="center"
                                width="400"
                                label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClickLine(scope.row)" type="text" size="small" class="greenBtn" v-if="!scope.row.isDispatch || scope.row.isDispatch === '否'">派工</el-button>
                                    <div v-else style="display:inline-block">
                                        <el-button  @click="handleReset(scope.row)" type="text" size="small" class="greenBtn">重新派工</el-button>
                                        <el-button  @click="handleCancel(scope.row)" type="text" size="small" class="greenBtn" style="margin-right:10px">取消派工</el-button>
                                    </div>
                                    <el-button @click="handleClickDetail(scope.row)" type="text" size="small" class="greenBtn">查看设备</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            title="派工"
            center
            v-drag
            :visible.sync="assignmentVisible"
            width="25%">
            <div>
                <label style="margin-right:10px">主要负责人</label>
                <el-select v-model="checkLeader" style="width:60%">
                    <el-option v-for="(item) in leadersArr" :key="item.id" :label="item.userName" :value="item.userId"></el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goDispatch">派工</el-button>
            </span>
        </el-dialog>
        <el-dialog
            class="newAddDevice"
            title="新增航线"
            center
            :visible.sync="newAddDialogShow"
            v-drag
            width="70%">
            <div style="width:100%;">
                <el-row style="width:100%" class="top-row">
                    <div style="width: auto" class="dialog_typeDiv">
                        <el-form :inline="true" class="demo-form-inline" label-width="80px" ref="form">
                            <!-- <el-form-item label="设备类型" prop="newDeviceType">
                                <el-select v-model="newDeviceForm.newDeviceType">
                                    <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.label" :value="item.value"  style="width:180px"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item label="航线名称" prop="newDeviceName">
                                <div class="dialog_type" >
                                    <el-input v-model="newAddSearchKey" placeholder="请输入" style="width:180px" clearable @change="newAddSearchChange" @keydown.enter.native="handleNewAdd(1)"></el-input>
                                </div>
                            </el-form-item>
                            <el-button class="dialog_typeBtn" @click="handleNewAdd(1)">查询</el-button>
                        </el-form>
                    </div>
                </el-row>
                <el-row style="width:100%" class="bottom-row">
                    <div style="width:100%;">
                        <el-table
                            class="myTable"
                            resizable
                            :header-cell-style="{background: '#FAFAFA',fontWeight: 400,color:'#259C9C',fontSize: '14px',fontFamily: 'Source Han Sans CN-Regular, Source Han Sans CN'}"
                            highlight-current-row
                            :data="newtableData"
                            stripe
                            border
                            max-height="410px"
                            height="410px"
                            tooltip-effect="light"
                            :row-style="{height: '40px'}"
                            :cell-style="{padding: '0.505%'}"
                            row-key="id"
                            @selection-change="handleSelectionChange"
                            style="width: 100%">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="航线名称"
                            align="center"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="oldFileName"
                            align="center"
                            label="kml航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="kmzOldFileName"
                            align="center"
                            label="kmz航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="txtOldFileName"
                            align="center"
                            label="道通航线文件"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <!-- <el-table-column
                            prop="isDispatch"
                            align="center"
                            label="是否已派工"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column>
                            <el-table-column
                            prop="person"
                            align="center"
                            label="当前派工"
                            show-overflow-tooltip
                            width="auto">
                            </el-table-column> -->
                        </el-table>
                    </div>
                </el-row>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="currentPage2"
                        :page-sizes="[10,20,50,100]"
                        :page-size="pageSize2"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="totalPage2">
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goNewAdd">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import api_uav from '@utils/api/api_uav'
import '@/utils/dialogdrag'
export default {
    name:'AirLine',
    props:{
        patrolTaskId:{
        }
    },
    data(){
        return{
            totalPage2: 0,
            airlineName:'',
            isFlaw:'',
            currentPage: 1,
            pageSize:10,
            totalPage:0,
            assignmentVisible: false,
            checkLeader:'',
            leadersArr:[],
            airlineId:'',
            tableColumns:[
                {
                    label:'航线名称',
                    prop:'name',
                },
                // {
                //     label:'设备数',
                //     prop:'lineName',
                // },
                {
                    label:'是否已派工',
                    prop:'isDispatch'
                },
                {
                    label:'当前派工',
                    prop:'person'
                },
            ],
            tableData: [],
            flag : 1,
            dispatchId:'',
            newAddSearchKey: '',
            newAddDialogShow: false,
            currentPage2: 1,
            pageSize2: 10,
            newtableData: [],
            tableMulSelect: []
        }
    },
    computed:{
        taskId(){
            return this.$store.state.lineTaskid
        },
        curId(){
            return this.$store.state.id
        },
        newDispatchIdId(){
            return this.$store.state.dispatchId
        }
    },
    mounted(){
        this.queryAirLineInfo()
    },
    methods:{
        newAddSearchChange(){
            if(!this.newAddSearchKey){
                this.currentPage2 = 1
                this.handleNewAdd()
            }
        },
        goNewAdd(){
            if(this.tableMulSelect.length < 1){
                this.$message.warning('请先选择要添加的航线')
                return
            }
            // console.log(this.tableMulSelect)
            let params = {
                airLineIds: this.tableMulSelect.map(x => x.id).join(','),
                patroltaskid: this.taskId
            }
            api_uav.queryAirLineDeviceByMulAirId(params).then(res => {
                if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    this.newAddDialogShow = false
                    this.queryAirLineInfo()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.tableMulSelect = val
        }, 
        handleSizeChange2(val){
            this.currentPage2 = 1
            this.pageSize2 = val
            this.handleNewAdd()
        },
        handleCurrentChange2(val){
            this.currentPage2 = val
            this.handleNewAdd()
        },
        handleNewAdd(flag){
            if(flag !== 1){
                this.newAddDialogShow = true
            }
            let params = {
                currentPage: this.currentPage2,
                pageSize: this.pageSize2,
                searchValue: this.newAddSearchKey,
            }
            api_uav.queryLineInfo(params).then(res => {
                if(res.data.code === 200){
                    // this.$message.success('')
                    this.newtableData = res.data.rows
                    this.totalPage2 = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        goSearch(){
            this.currentPage = 1
            this.queryAirLineInfo()
        },
        goDispatch(){
            // console.log(this.newDispatchIdId)
            if(!this.checkLeader){
                this.$message.warning('请选择负责人')
                return
            }
            let params = {
                airLineId:this.airlineId,
                taskId:this.newDispatchIdId,
                personId:this.checkLeader
            }
            if(this.flag === 2){ //重新指派
                params.id = this.dispatchId
                this.axios.put('/api/patrol/dispatch',params, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            }).then(res => {
                    if(res.data.code === 200){
                        this.sendMessage()
                        this.checkLeader = ''
                        this.assignmentVisible = false
                        this.queryAirLineInfo()
                        this.$message.success(res.data.msg)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }else{
                api_uav.handleDispatch(params).then(res => {
                    if(res.data.code === 200){
                        this.sendMessage()
                        this.checkLeader = ''
                        this.assignmentVisible = false
                        this.queryAirLineInfo()
                        this.$message.success(res.data.msg)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        sendMessage(){
            // let url = 'http://47.106.229.82:8888/sms.aspx'
            // let params = {
            //     action:'send',
            //     userid:8035,
            //     account:'西安宇航者',
            //     password:123456,
            //     mobile:17698050018,
            //     content:'123456789',
            //     sendTime:null,
            //     extno:null
            // }
            // this.axios.post(url,params).then(res => {
            //     console.log(res)
            // })
            let params = {
                personId: this.checkLeader
            }
            api_uav.queryUserList(params).then(res => {
                let phoNumber = res.data.rows[0].phonenumber
                let finalMess = MESSAGE_CONTEXT.replace('XXX',res.data.rows[0].userName)
                let url = `/mess?action=send&userid=${MESSAGE_COUNT.userid}&account=${MESSAGE_COUNT.account}&password=${MESSAGE_COUNT.password}&mobile=${phoNumber}&content=${finalMess}&sendTime=&extno=`
                this.axios.post(url).then(res => {
                    let result = res.data.split('<message>')[1].split('</message>')[0]
                    if(result !== 'ok'){
                        console.log(`短信服务: ${result}`)
                        this.$message.error(`短信服务: ${result}`)
                    }else{
                        this.$message.success(`短信已发送`)
                    }
                })
            })
            
        },
        resetSearch(){
            this.currentPage = 1
            this.airlineName = ''
            this.queryAirLineInfo()
        },
        searchChange(){
            if(!this.airlineName){
                this.currentPage = 1
                this.queryAirLineInfo()
            }
        },
        queryAirLineInfo(){
            let params = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                airLineName: this.airlineName,
                patrolTaskId: this.taskId
            }
            api_uav.queryAirLineInfo(params).then(res => {
                if(res.data.code === 200){
                    this.tableData = res.data.rows
                    this.tableData.forEach(item => {
                        item.person = item.person ? item.person : '--'
                    })
                    this.totalPage = res.data.total
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.queryAirLineInfo()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.queryAirLineInfo()
        },
        handleClickLine(item){
            this.checkLeader = ''
            this.flag = 1
            this.airlineId = item.id
            api_uav.queryUserList().then(res => {
                if(res.data.code === 200){
                    this.leadersArr = res.data.rows.map(x => {
                        return {
                            userName:x.userName,
                            userId:x.userId
                        }
                    })
                    this.assignmentVisible = true
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleReset(item){
            this.airlineId = item.id
            this.checkLeader = ''
            this.dispatchId = item.dispatchId
            this.flag = 2
            api_uav.queryUserList().then(res => {
                if(res.data.code === 200){
                    this.leadersArr = res.data.rows.map(x => {
                        return {
                            userName:x.userName,
                            userId:x.userId
                        }
                    })
                    this.assignmentVisible = true
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleCancel(item){
            this.$confirm('确定要取消派工吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api_uav.cancelDispatch(item.dispatchId).then(res => {
                        if(res.data.code === 200){
                            this.checkLeader = ''
                            this.$message.success(res.data.msg)
                            this.queryAirLineInfo()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });          
                });
                return false;
        },
        handleClickDetail(item){
            let params = {
                tab:'task_airlinedevice',
                // taskid:item.taskid,
                id:item.id
            }
            this.$store.commit('clickLabel', params)
            this.$store.commit('clickTab', params.tab)
        }
    }
}
</script>
<style lang="stylus" scoped>
.airline
    width: 100%
    height: 100%
    .rightContent
        width: 100%
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        .top_first
        .top_second
            position: absolute
            height: 40px
            width:100%
            div
                width: 30%
                display: inline-block
                label
                    margin: 0 5%
                >>>.el-input
                    width: 60%
                    height: 36px
                >>>.el-select
                    width: 60%
                    height: 36px
                >>>.el-input__inner
                    height: 36px
        .top_second
            top:60px
            div
                >>>.el-input
                    width: 100%
                    height: 36px
        .top_first
            // padding:  1% 0
            top:20px
            .Btn
                // float: right
                // text-align: right 
                margin-right: 25px
                >>>.el-button
                    width: 80px
                    height: 32px
                    display: inline-flex
                    align-items: center
                    justify-content: center
                .search
                    background: #259C9C;
                    color: white
                .newAdd
                    background: rgba(37,156,156,0.1);
                    border: 1px solid #259C9C;
                    color: #259C9C
        .bottom
            // flex: 1
            // margin-top:20px
            position: absolute
            top:80px
            width: 100%
            // height: calc(91% - 36px)
            // height: 80%
            display: inline-flex
            justify-content: center
            .inner
                width: 97%
                height: 100%
            .greenBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #259C9C;
            .redBtn
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
            >>>.el-table__body-wrapper
            >>>.el-table__fixed-body-wrapper
                max-height: calc(100vh - 350px);
        .pagination
            position: absolute
            bottom: 0
            width: 100%
            height: 60px
            border-top:  1px solid rgba(73,92,106,0.2);
            >>>.el-pagination
                position: absolute
                right: 10px
                bottom: 12px
</style>