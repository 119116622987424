<template>
  <div id="app">
    <Home/>
    <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: #5c6b77">
      <a target="_blank"
      style="color: #5c6b77"
      href="https://beian.miit.gov.cn/"> 陕ICP备2023019176号</a>
    </div>
  </div>
</template>

<script>
import Home from '@views/Home.vue'
export default {
  name: 'App',
  components: {
    Map,
    Home
  },
  data() {
    return {
    }
  },
  computed: {
  },
  
}
</script>
<style>
body,html{
  width: 100%;
  height: 100%;
  margin: 0;
  /* min-width: 1340px; */
}
/* body::-webkit-scrollbar {
    width : 10px;
    height: 1px;
}
body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
    background   : #ededed;
}
body::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px rgba(250, 250, 250, 0.2);
    border-radius: 10px;
    background   : transparent;
} */
</style>
<style lang="stylus" scoped>
#app
  width: 100%
  height: 100%
  position: relative
  left: 0
  top: 0
  // background: #EEEDF0
  background: url('@images/common/background.png')  no-repeat center center;
  background-size: 100% 100%
</style>
