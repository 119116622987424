//配置路由相关信息
import VueRouter from 'vue-router'
import Vue from 'vue'
import store from '../store/index'
//引入要跳转的组件
import Map from '@views/patrolResult/Map'
import PictureManager from '@views/patrolResult/PictureManage'
import PatrolTask from '@views/patrolSelf/PatrolTask'
import DefectAnalysis from '@views/statisticalReports/DefectAnalysis'
import LineManager from '@views/dataLedger/LineManage.vue'
import UavManager from '@views/dataLedger/UavManage.vue'
import AppUserManage from '@views/systemManage/AppUserManage.vue'
import LiveVideo from '@views/patrolResult/LiveVideo.vue'
import TaskManage from '@views/patrolSelf/TaskManage'
import AirLine from '@views/patrolSelf/AirLine'
import AirLineDevice from '@views/patrolSelf/AirLineDevice'
import PatrolDevice from '@views/patrolSelf/PatrolDevice'
// import 
//安装插件
Vue.use(VueRouter)

//push 
const VueRouterPush = VueRouter.prototype.push 
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}
//创建VueRouter对象
const routes = [
    {
        path:'/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
        },
        components:{
          'login': () => import('@/views/Login/index'),
        },
    },
    {
        path:'/task_manage',
        // name:'patrol_task',
        component: PatrolTask,
        children:[
            //巡检任务管理部分路由
            {
                path:'/task_manage',
                name:'task_manage',
                component: TaskManage   
            },
            {
                path:'/task_airline',
                name:'task_airline',
                component: AirLine     
            },
            {
                path:'/task_airlinedevice',
                name:'task_airlinedevice',
                component: AirLineDevice 
            },
            {
                path:'/task_patrolDevice',
                name:'task_patrolDevice',
                component: PatrolDevice   
            },
        ]
    },
    {
        path:'/picture_manage',
        name:'picture_manage',
        component: PictureManager
    },
    {
        path:'/defect_analysis',
        name:'defect_analysis',
        component: DefectAnalysis
    },
    {
        path:'/map',
        name:'map',
        component: Map
    },
    {
        path:'/live_video',
        name:'live_video',
        component: LiveVideo
    },
    {
        path:'/line_manage',
        name:'line_manage',
        component: LineManager
    },
    {
        path:'/uav_manage',
        name:'uav_manage',
        component: UavManager  
    },
    {
        path:'/app_user_manage',
        name:'app_user_manage',
        component: AppUserManage   
    },



    

]

//配置路由和组件之间的应用关系
const router = new VueRouter({
    routes
})
export default router

// router.beforeEach((to,from,next) => {
    
//     // if (sessionStorage.getItem('token')) {
//         // console.log(to.path)
//         sessionStorage.setItem('recordPath',to.path)
//         // console.log(from.path)
//         next()
//     // } else {
//         // next({//返回登录页面
//         //   path: '/',
//         // })
//     // }
// })
router.beforeEach((to, from, next) => {
    // 如果访问的是登录界面则直接放行
    if (to.path === '/login') return next()
    //获取用户页面token信息
    let token = sessionStorage.getItem('token')
    //如果token数据为null则跳转到指定路径
    if (!token) {
        return next("/login")
    } else{
        sessionStorage.setItem('recordPath',to.path)
        next()
    }
    next()
  })
  